import React from "react";
import {useConfig} from "../../../../Services/ConfigContext";

const PromoteRightSectionItem = ({data}) => {

    const config = useConfig();

    return (<div className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_over_bottom_center sc_blogger_item_odd sc_blogger_item_align_center post_format_standard sc_blogger_item_with_image sc_blogger_item_image_position_top sc_blogger_title_tag_h4 post-955 post type-post status-publish format-standard has-post-thumbnail hentry category-pottery tag-article tag-news tag-story">
            <div className="sc_blogger_item_body">
                <div
                    className="post_featured with_thumb hover_inherit sc_item_featured sc_blogger_item_featured post_featured_bg mb-0"
                    data-ratio="10:7">
                    <div className="featured_bg_wrapper">
                        <div className="featured_bg"
                             style={{backgroundImage: 'url(' + config.imagesPath + data.background + ')'}}></div>
                    </div>
                    <div className="mask"></div>
                    <div className="post_info_bc">
                        <div className="post_meta sc_blogger_item_meta post_meta_categories">
                                                                                                            <span className="post_meta_item post_categories">
                                                                                                                <a href={data.linkUrl}>{data.categoryTitle}</a></span>
                        </div>
                        <h4 className="sc_blogger_item_title entry-title"
                            data-item-number="1">
                            <a href={data.linkUrl}>{data.title}</a>
                        </h4>
                        <div
                            className="post_meta sc_blogger_item_meta post_meta">
                            <span className="post_meta_item post_date">
                                <a href={data.linkUrl}>{data.date}</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default PromoteRightSectionItem