import "./ElementBoxView.css"
import {useEffect, useState} from "react";
import {useConfig} from "../../../Services/ConfigContext";
import React from 'react';

const ElementBoxView = (props) => {

    const [data, setData] = useState({
        boxes: [],
        layoutColsDesktop: 3, // 2,3,4
        layoutColsMobile: 6, // 6,12,
        overFlowOption: 'center' //center,flex-start
    })

    const config = useConfig();

    useEffect(() => {
        if (props) {
            setData({...data, ...props.data})
        }
    }, [props]);

    return (


        <div className={"row row-overflow-" + data.overFlowOption}>
            {
                data.boxes.map((b, index) => {
                    return (
                        <div key={index}
                             className={"p-2 col-" + data.layoutColsMobile + " col-lg-" + data.layoutColsDesktop + " wow fadeInUp animated"}>
                            <div className="service-card-three">
                                <div className="service-card-three__image">
                                    {b.image.length > 0 &&
                                        <img src={config.imagesPath + b.image} alt={b.title}/>
                                    }
                                </div>
                                <div className="service-card-three__content">
                                    {b.icon.length > 0 &&
                                    <div className="service-card-three__icon-box">
                                        <span className="service-card-three__icon"><i className={b.icon}></i></span>
                                    </div>
                                    }
                                    <h3 className="service-card-three__title">
                                        {b.linkUrl.length > 0 &&
                                            <a href={b.linkUrl}>{b.title}</a>
                                        }
                                        {b.linkUrl.length === 0 && b.title}
                                    </h3>
                                    <p className="service-card-three__text"
                                       dangerouslySetInnerHTML={{__html: b.content}}></p>
                                    {b.linkUrl.length > 0 &&
                                        <a href={b.linkUrl} className="service-card-three__link fs-6 mt-2">{b.linkText}</a>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ElementBoxView