import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import PageContainer from "../PageContainer/PageContainer";
import HomePageService from "../../Services/HomePageService";
import {Helmet} from "react-helmet-async";
import React from 'react';
import {useConfig} from "../../Services/ConfigContext";


const PageRenderer = () => {
    const pageUrl = useParams()["*"];
    const [pageData, setPageData] = useState(null);
    useEffect(() => {
        HomePageService.getPage(pageUrl).then(response => {
            let data = response.data;
            if (data.hasOwnProperty("content")) {
                data.content = JSON.parse(data.content);
            }
            setPageData(data);
        })
    }, []);

    const config = useConfig();
    const bannerData = config.banner;


    return (pageData !== null &&
        <>
            {bannerData &&  <Helmet>
                <title>{bannerData.metaTitle} - {pageData.title}</title>
                <meta name="description" content={pageData.metaDescription}/>
            </Helmet>
            }
            <PageContainer page={pageData}/>
        </>
    );
}

export default PageRenderer