import React, {useEffect, useState} from "react";
import swal from "sweetalert2";
import toast from "react-hot-toast";
import ManagementHomePageService from "../../../Services/ManagementHomePageService";
import SliderEdit from "./SliderEdit/SliderEdit";
import {ReactSortable} from "react-sortablejs";
import SliderPageContent from "./PageContent/SliderPageContent";


const Slider = () => {

    const [pageContentModalState, setPageContentModalState] = useState(false)
    const [editingSlider, setEditingSlider] = useState(null);
    const [sliderSorting, setSliderSorting] = useState(false);

    const onSliderEditorClose = () => {
        setEditingSlider(null);
        setPageContentModalState(false)
        getData();
    }

    const [data, setData] = useState([]);
    const deleteDialog = (itemId) => {
        swal.fire({
            title: "İşleme devam et",
            html: "Nesne silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                ManagementHomePageService.deleteSlider(itemId).then(response => {
                    toast.success('Nesne silindi');
                    getData();
                }).catch(e => {
                    toast.error(e.response.data);
                })
            }
        })
    }

    const getData = () => {
        ManagementHomePageService.getSliders().then(response => {
            setData(response.data
                .sort((a, b) => a.order > b.order ? 1 : -1));
        });
    }

    const saveSliderSorting = () => {
        let sortCount = 0;
        let newSortList = data.map((d) => {
            sortCount++;
            return {
                id: d.id,
                order: sortCount
            }
        })
        ManagementHomePageService.saveSliderSorts(newSortList).then(response => {
            toast.success("Sıralama kaydedildi");
            setSliderSorting(false);
        });
    }

    useEffect(() => {
        if (!sliderSorting) {
            getData();
        }
    }, [sliderSorting]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="col-12 mb-10">
            {editingSlider === null &&
                <div className={"col-12 mt-2"}>
                    <div className={"text-right p-0 mb-2"}>
                        {!sliderSorting &&
                            <a onClick={() => setSliderSorting(true)} className="btn btn-secondary mx-1"><i
                                className={"fa fa-sort mx-1"}></i> Sıralamayı Etkinleştir</a>}
                        {sliderSorting &&
                            <a onClick={() => setSliderSorting(false)} className="btn btn-secondary mx-1"><i
                                className={"fa fa-cancel mx-1"}></i> Sıralamayı İptal Et</a>}
                        {sliderSorting && <a onClick={() => saveSliderSorting()} className="btn btn-secondary mx-1"><i
                            className={"fa fa-check mx-1"}></i> Sıralamayı Kaydet</a>}
                        <a onClick={() => setEditingSlider(-1)} className="btn btn-secondary mx-1"><i
                            className={"fa fa-plus"}></i> Nesne Ekle</a>
                        <a onClick={() => setPageContentModalState(true)} className="btn btn-secondary mx-1"><i
                            className={"fa fa-pencil"}></i> Bölüm Başlığı ve Özellikleri Düzenle</a>
                    </div>
                    <table className="table management__table">
                        <thead>
                        <tr>
                            <th>Başlık</th>
                            <th>Bağlantı</th>
                            <th>Operasyonlar</th>
                        </tr>
                        </thead>
                        <ReactSortable list={data} setList={setData} disabled={!sliderSorting} tag={"tbody"}>
                            {
                                data.map(d => {
                                    return (
                                        <tr key={d.id} draggable={true}>
                                            <td width={200}>
                                                {d.title}
                                            </td>
                                            <td>
                                                {d.linkUrl}
                                            </td>
                                            <td className="text-right">
                                                <a onClick={() => setEditingSlider(d.id)}
                                                   className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                                <a onClick={() => {
                                                    deleteDialog(d.id)
                                                }} className="btn btn-outline-danger btn-sm mx-1 my-1">Nesneyi Sil</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </ReactSortable>
                    </table>
                </div>
            }

            {editingSlider != null &&
                <SliderEdit sliderId={editingSlider} onClose={() => onSliderEditorClose()}></SliderEdit>
            }

            {pageContentModalState &&
                <SliderPageContent onClose={onSliderEditorClose}></SliderPageContent>
            }
        </div>
    )
}

export default Slider