import BaseService from './BaseService';

class ManagementHomePageService extends BaseService {

    getPageContent(pageCategory) {
        return this.apiClient.get('HomePageManagement/PageContent/'+pageCategory);
    }

    savePageContent(pageCategory,content) {
        return this.apiClient.post('HomePageManagement/PageContent/'+pageCategory, {content: content});
    }



    getFeatures() {
        return this.apiClient.get('HomePageManagement/Features');
    }

    getFeature(featureId) {
        return this.apiClient.get('HomePageManagement/Feature/' + featureId);
    }

    saveFeature(feature) {
        return this.apiClient.put('HomePageManagement/Feature', feature);
    }

    deleteFeature(featureId) {
        return this.apiClient.delete('HomePageManagement/Feature/' + featureId);
    }

    saveFeatureSorts(sortList) {
        return this.apiClient.post('HomePageManagement/Feature/Sort', sortList);
    }

    getSliders() {
        return this.apiClient.get('HomePageManagement/Sliders');
    }

    getSlider(productId) {
        return this.apiClient.get('HomePageManagement/Slider/' + productId);
    }

    saveSlider(product) {
        return this.apiClient.put('HomePageManagement/Slider', product);
    }

    deleteSlider(productId) {
        return this.apiClient.delete('HomePageManagement/Slider/' + productId);
    }

    saveSliderSorts(sortList) {
        return this.apiClient.post('HomePageManagement/Slider/Sort', sortList);
    }


    getPromotes() {
        return this.apiClient.get('HomePageManagement/Promotes');
    }

    getPromote(productId) {
        return this.apiClient.get('HomePageManagement/Promote/' + productId);
    }

    savePromote(product) {
        return this.apiClient.put('HomePageManagement/Promote', product);
    }

    deletePromote(productId) {
        return this.apiClient.delete('HomePageManagement/Promote/' + productId);
    }

    savePromoteSorts(sortList) {
        return this.apiClient.post('HomePageManagement/Promote/Sort', sortList);
    }

    getPinnedProducts() {
        return this.apiClient.get('HomePageManagement/Product/Pinned');
    }
    saveProductSorts(sortList) {
        return this.apiClient.post('HomePageManagement/Product/Sort', sortList);
    }

    getPinnedEducations() {
        return this.apiClient.get('HomePageManagement/Education/Pinned');
    }
    saveEducationSorts(sortList) {
        return this.apiClient.post('HomePageManagement/Education/Sort', sortList);
    }

}

export default new ManagementHomePageService();