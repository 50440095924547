import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import "./EditorSidebarContent.css"
import {useConfig} from "../../../../../Services/ConfigContext";
import BaseService from "../../../../../Services/BaseService";
import ElementSidebarContent from "../../../../../Pages/PageElements/ElementSidebarContent/ElementSidebarContent";
import TextEditor from "../../TextEditor/TextEditor";

const EditorSidebarContent = (props) => {

    const [zoomRatio, setZoomRatio] = useState(false);
    const divWidthRef = useRef();
    const containerWidth = 1200;

    const config = useConfig();

    const [editingSidebarItemIndex, setEditingSidebarItemIndex] = useState(null);
    const [editingSidebarItemTitle, setEditingSidebarItemTitle] = useState("");
    const [editingSidebarItemUrl, setEditingSidebarItemUrl] = useState("");

    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / containerWidth);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);


    const [form, setForm] = useState({
        contentPosition: 'right', //left, right
        sidebarColsDesktop: 6, // 2-8
        sidebarColsMobile: 12, // 6-12
        sidebarItems: [],
        sidebarTitle: "",
        content: '',
    });

    const sidebarPositionOptions = [
        {value: 'left', label: 'Sol'},
        {value: 'right', label: 'Sağ'}
    ]

    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }


    useEffect(() => {
        if (props.hasOwnProperty("data")) {
            setForm({...form, ...props.data});
        }
    }, [props]);


    const editSidebarItem = (index) => {
        setEditingSidebarItemTitle(form.sidebarItems[index].title);
        setEditingSidebarItemUrl(form.sidebarItems[index].url);
        setEditingSidebarItemIndex(index);
    }

    const addSidebarItem = () => {
        setEditingSidebarItemIndex(-1);
        setEditingSidebarItemTitle('');
        setEditingSidebarItemUrl('');
    }

    const deleteSidebarItem = (index) => {
        setForm(prevForm => {
            const updatedSidebarItems = prevForm.sidebarItems.filter((_, i) => i !== index);
            return {...prevForm, sidebarItems: updatedSidebarItems};
        });
        setEditingSidebarItemIndex(null);
    };

    const saveSidebarItem = () => {
        setForm(prevForm => {
            const updatedSidebarItems = editingSidebarItemIndex > -1
                ? prevForm.sidebarItems.map((sidebarItems, i) => i === editingSidebarItemIndex ? {
                    title: editingSidebarItemTitle,
                    url: editingSidebarItemUrl
                } : sidebarItems)
                : [...prevForm.sidebarItems, {title: editingSidebarItemTitle, url: editingSidebarItemUrl}];

            return {...prevForm, sidebarItems: updatedSidebarItems};
        });
        setEditingSidebarItemIndex(null);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            var baseService = new BaseService();
            baseService.uploadDocument(selectedFile).then(response => {
                setEditingSidebarItemUrl(config.imagesPath+response.data);
            })
        }
    };

    return (
        <div>
            <div className={"row mb-30"}>
                <div className={"col-md-12"} ref={divWidthRef}>
                    <label className={"form-label"}>Önizleme</label>
                </div>
                <div className={"col-md-12"} style={{zoom: zoomRatio}}>
                    <ElementSidebarContent data={form}></ElementSidebarContent>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>İçerik Pozisyonu</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={sidebarPositionOptions.find(ipo => ipo.value === form.contentPosition)}
                                onChange={(selectedOption) => handleForm("contentPosition", selectedOption.value)}
                                options={sidebarPositionOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
                <div className={"col-md-5"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Sidebar ve İçerik Oranı (Masaüstü)</label>
                    </div>
                    <div className={"col-md-12 d-flex"}>
                        {
                            [...Array(6)].map((v, i) => {
                                return <div onClick={() => handleForm("sidebarColsDesktop", (i + 1) * 2)} key={i}
                                            className={"grid-template d-flex col-md-1 " + (form.sidebarColsDesktop >= (i + 1) * 2 ? 'active-grid-template' : '')}>
                                    {
                                        form.sidebarColsDesktop >= (i + 1) * 2 &&
                                        <i className={"fa fa-list text-white m-auto"}></i>
                                    }
                                    {
                                        form.sidebarColsDesktop < (i + 1) * 2 &&
                                        <i className={"fa fa-pencil m-auto"}></i>
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className={"col-md-4"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Sidebar ve İçerik Oranı (Mobil)</label>
                    </div>
                    <div className={"col-md-12 d-flex"}>
                        {
                            [...Array(2)].map((v, i) => {
                                return <div onClick={() => handleForm("sidebarColsMobile", (i + 1) * 6)} key={i}
                                            className={"grid-template d-flex col-md-1 " + (form.sidebarColsMobile >= (i + 1) * 6 ? 'active-grid-template' : '')}>
                                    {
                                        form.sidebarColsMobile >= (i + 1) * 6 &&
                                        <i className={"fa fa-list text-white m-auto"}></i>
                                    }
                                    {
                                        form.sidebarColsMobile < (i + 1) * 6 &&
                                        <i className={"fa fa-pencil m-auto"}></i>
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

            <div className={"col-md-12 mt-4"}>
                <label className={"form-label"}>İçerik</label>
            </div>

            <div className={"col-md-12 "}>
                <TextEditor onEditorChange={(e) => handleForm("content", e)}
                            initialValue={form.content}></TextEditor>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-md-12"}>
                    <label className={"form-label"}>Sidebar Başlığı</label>
                </div>
                <div className={"col-md-12"}>
                    <input className="form-control" value={form.sidebarTitle}
                           onChange={(e) => handleForm("sidebarTitle", e.target.value)}/>
                </div>
            </div>

            <div className={"text-right mt-4"}>
                <a className="btn btn-secondary" onClick={() => addSidebarItem()}><i
                    className={"fa fa-plus mx-1"}></i> Yeni Ekle</a>
            </div>
            <table className={"table management__table mt-4"}>
                <thead>
                <tr>
                    <th>Başlık</th>
                    <th>Bağlantı</th>
                    <th>Operasyonlar</th>
                </tr>
                </thead>
                <tbody>
                {form.sidebarItems.map((s, index) => {
                        return (
                            <tr key={index}>
                                <td>{s.title}</td>
                                <td>{s.url}</td>
                                <td className="text-right">
                                    <a onClick={() => editSidebarItem(index)}
                                       className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                    <a onClick={() => {
                                        deleteSidebarItem(index)
                                    }} className="btn btn-outline-danger btn-sm mx-1 my-1">Sil</a>
                                </td>
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>

            <div className={"modal-footer"}>
                <div className={"col-md-12 text-right"}>
                    <a className="btn btn-secondary mx-2" onClick={() => props.closeElementEditor()}><i
                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                    <a className="btn btn-secondary" onClick={() => props.saveElement(form)}><i
                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                </div>
            </div>


            {
                editingSidebarItemIndex !== null &&
                <div className={"modal"} id="staticBackdrop">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{editingSidebarItemIndex === -1 ? 'Ekle' : 'Düzenle'}</h5>
                                <button type="button" className="btn-close"
                                        onClick={() => setEditingSidebarItemIndex(null)}></button>
                            </div>
                            <div className="modal-body">

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Başlık</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <input className="form-control" value={editingSidebarItemTitle}
                                           onChange={(e) => setEditingSidebarItemTitle(e.target.value)}/>
                                </div>

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>URL</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <input className="form-control" value={editingSidebarItemUrl}
                                           onChange={(e) => setEditingSidebarItemUrl(e.target.value)}/>
                                </div>

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Dosya Yükle</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        onChange={(e) => handleFileChange(e)}
                                    />
                                </div>

                                <div className={"col-md-12 text-right mt-4"}>
                                    <a className="btn btn-secondary mx-2"
                                       onClick={() => setEditingSidebarItemIndex(null)}><i
                                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                                    <a className="btn btn-secondary" onClick={() => saveSidebarItem()}><i
                                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditorSidebarContent;