import BaseService from './BaseService';

class ManagementPageDetailService extends BaseService {

    getPageDetails(search,page) {
        return this.apiClient.get('HomePageManagement/Pages?search='+search+'&page='+page);
    }
    savePageDetail(pageDetail) {
        return this.apiClient.post('HomePageManagement/Page',pageDetail);
    }
    getPageDetail(pageDetailId) {
        return this.apiClient.get('HomePageManagement/Page/'+pageDetailId);
    }
    deletePage(pageDetailId) {
        return this.apiClient.delete('HomePageManagement/Page/'+pageDetailId);
    }

}

export default new ManagementPageDetailService();