import "./Footer.css"
import {useEffect, useRef, useState} from "react";
import {Tree} from "react-arborist";
import FooterEdit from "./FooterEdit/FooterEdit";
import {v4 as uuidv4} from 'uuid';
import swal from "sweetalert2";
import toast from "react-hot-toast";
import ManagementThemeService from "../../../Services/ManagementThemeService";
import React from 'react';

const Footer = ({disableNodeMove = true}) => {

    const [containerWidth, setContainerWidth] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);
    const containerRef = useRef();
    const treeRef = useRef();

    const [operatingItem, setOperatingItem] = useState(null);

    const onModalClose = () => {
        setOperatingItem(null);
    }

    const onModalSave = (newNode) => {
        let nodeFound = false;
        const updateNodeId = (nodes) => {
            return nodes.map(node => {
                if (node.id === newNode.id) {
                    node.url = newNode.url;
                    node.name = newNode.name;
                    nodeFound = true;
                    return node;
                }
                if (node.children) {
                    node.children = updateNodeId(node.children);
                }
                return node;
            });
        };

        const addNodeToParent = (nodes, parentId, nodeToAdd) => {
            return nodes.map(node => {
                if (node.id === parentId) {
                    if (!node.children) {
                        node.children = [];
                    }
                    node.children.push(nodeToAdd);
                } else if (node.children) {
                    node.children = addNodeToParent(node.children, parentId, nodeToAdd);
                }
                return node;
            });
        };

        const updatedData = updateNodeId(treeData);
        if (!nodeFound) {
            addNodeToParent(updatedData, newNode.parentId, newNode);
        }

        onModalClose();
        setTreeData(updatedData);
    };

    const getTotalElements = () => {
        let count = 0;

        const countRecursive = (arr) => {
            for (const item of arr) {
                count++;
                if (item.hasOwnProperty('children') && item.children !== null) {
                    countRecursive(item.children);
                }
            }
        };

        countRecursive(treeData);
        return count;
    };


    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.clientWidth)
        }
    }, [containerRef]);


    const [treeData, setTreeData] = useState([]);

    const getFooter = () => {
        ManagementThemeService.getFooter().then(response => {
            setTreeData(response.data);
        })
    }

    useEffect(() => {
        setContentHeight(45 * getTotalElements());
    }, [treeData])

    useEffect(() => {
        getFooter();
    }, []);

    const addMainItem = () => {
        let newObject = {parentId: -1, name: '', url: '', id: uuidv4()};
        setTreeData([...treeData, newObject]);
        setOperatingItem(newObject);
    }

    const deleteNode = (idToRemove, name) => {
        const removeNode = (nodes, id) => {
            return nodes.filter(node => {
                if (node.id === id) {
                    return false;
                }
                if (node.children) {
                    node.children = removeNode(node.children, id);
                }
                return true;
            });
        };

        swal.fire({
            title: "İşleme devam et",
            html: "<b>" + name + "</b> isimli footer silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                let newObj = removeNode(treeData, idToRemove);
                setTreeData(newObj);
            }
        })
    };

    const findNodeAndRemove = (nodes, dragIds) => {
        return nodes.filter(node => {
            if (dragIds.includes(node.id)) {
                return false; // Bu node'u kaldır
            }
            if (node.children) {
                node.children = findNodeAndRemove(node.children, dragIds);
            }
            return true;
        });
    };


    const findNodeAndAdd = (nodes, parentId, dragNodes, index) => {
        return nodes.map(node => {
            if (node.id === parentId) {
                if (!node.children) {
                    node.children = [];
                }
                const newNodes = dragNodes.map(dragNode => dragNode.data);
                node.children.splice(index, 0, ...newNodes);
            } else if (node.children) {
                node.children = findNodeAndAdd(node.children, parentId, dragNodes, index);
            }
            return node;
        });
    };

    const handleMove = ({dragNodes, dragIds, parentId, index}) => {
        if (dragNodes[0].children !== null && parentId !== null) {
            return false;
        }
        let newTreeData = [...treeData];
        newTreeData = findNodeAndRemove(newTreeData, dragIds);
        if (parentId === null) {
            const newNodes = dragNodes.map(dragNode => dragNode.data);
            newTreeData.splice(index, 0, ...newNodes);
        } else {
            newTreeData = findNodeAndAdd(newTreeData, parentId, dragNodes, index);
        }

        setTreeData(newTreeData);
    };

    const saveFooter = () => {
        ManagementThemeService.setFooter(treeData).then(response => {
            toast.success('Footer kaydedildi');
            getFooter();
        }).catch(e => {
            toast.error('Footer kaydedilemedi');
        })
    }


    const Node = ({node, style, dragHandle, tree}) => {

        const operatingObject = {
            parentId: node.parent.id,
            id: node.data.id,
            url: node.data?.url,
            name: node.data.name
        }

        const newObject = {
            parentId: node.id,
            id: uuidv4(),
            url: '',
            name: ''
        }

        return (
            <div className={"col-12"}>
                <div className="node-container custom-node" style={style} ref={dragHandle}>
                    <div className={"node-element"}>
                        {(!node.isLeaf && node.data.hasOwnProperty('children') && node.data.children.length > 0 &&
                            <i className={'fa fa-caret-right'}></i>)}
                        {(node.isLeaf || !node.data.hasOwnProperty('children') || node.data.children.length === 0) &&
                            <i className='fa fa-link'></i>}
                        <span className={"px-2"}>{node.data.name}</span>
                        <small><i>{node.data.url}</i></small>
                        <div className={"node-actions"}>
                            <a className={"btn btn-sm btn-outline-success py-0 mx-1"}
                               onClick={() => setOperatingItem(operatingObject)}>Düzenle</a>
                            {(node.isLeaf || !node.data.hasOwnProperty('children') || node.data.children.length === 0) &&
                                <a className={"btn btn-sm btn-outline-danger py-0 mx-1"}
                                   onClick={() => deleteNode(node.data.id, node.data.name)}>Sil</a>}
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className={"row p-0"} ref={containerRef}>
            <div className={"text-right p-0"}>
                <a className="btn btn-secondary mb-2" onClick={() => addMainItem()}><i
                    className={"fa fa-plus"}></i> Footer Ekle</a>
            </div>

            {
                <Tree ref={treeRef}
                      disableMultiSelection={true}
                      width={containerWidth}
                      data={treeData}
                      height={contentHeight}
                      rowHeight={45}
                      onMove={handleMove}>
                    {Node}
                </Tree>
            }
            <hr/>
            <div className={"text-right"}>
                <a className="btn btn-secondary" onClick={() => saveFooter()}><i
                    className={"fa fa-save"}></i> Kaydet</a>
            </div>
            {operatingItem && <FooterEdit onModalClose={onModalClose} item={operatingItem} onModalSave={onModalSave}/>}
        </div>
    );
}

export default Footer;
