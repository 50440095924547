import React, {useEffect, useRef, useState} from "react";
import swal from "sweetalert2";
import toast from "react-hot-toast";
import ManagementHomePageService from "../../../Services/ManagementHomePageService";
import EducationsPageContent from "./PageContent/EducationsPageContent";
import {ReactSortable} from "react-sortablejs";
import Search from "../../Layout/Search/Search";
import ManagementEducationsService from "../../../Services/ManagementEducationsService";


const Educations = () => {

    const [pageContentModalState, setPageContentModalState] = useState(false)
    const [editingEducation, setEditingEducation] = useState(null);
    const [itemPinning, setItemPinning] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchData, setSearchData] = useState({result: []});

    const search = () => {
        ManagementEducationsService.getEducations(searchValue, 1).then(response => {
            setSearchData(response.data);
        });
    }
    const onEducationEditorClose = () => {
        setEditingEducation(null);
        setPageContentModalState(false)
        getData();
    }

    const [data, setData] = useState([]);
    const deleteDialog = (index) => {
        swal.fire({
            title: "İşleme devam et",
            html: "Nesne silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                var currentData = data;
                currentData.splice(index, 1);
                setData([]);
                setTimeout(() => {
                    setData(currentData);
                })
            }
        })
    }

    const getData = () => {
        ManagementHomePageService.getPinnedEducations().then(response => {

            setData(response.data.length > 0 ? response.data : []);
        });
    }

    const saveEducationSorting = () => {
        let sortCount = 0;
        let newSortList = data.map((d) => {
            sortCount++;
            return {
                id: d.id,
                order: sortCount
            }
        })

        ManagementHomePageService.saveEducationSorts(newSortList).then(response => {
            toast.success("Sıralama kaydedildi");
        });
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const pinItem = (item) => {
        var currentData = data;
        currentData.push(item);
        setData(currentData);
        toast.success("Nesne pinlendi, listeye dönüp kaydedin.");
        search();
    }




    useEffect(() => {
        search();
    }, [searchValue]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="col-12 mb-10">
            {editingEducation === null &&

                <div className={"col-12 mt-2"}>
                    <div className={"text-right p-0 mb-2"}>

                        {!itemPinning &&
                            <a onClick={() => setItemPinning(true)} className="btn btn-secondary mx-1"><i
                                className={"fa fa-search mx-1"}></i> Nesne Ara ve Pinle</a>}

                        {itemPinning &&
                            <a onClick={() => setItemPinning(false)} className="btn btn-secondary mx-1"><i
                                className={"fa fa-arrow-left mx-1"}></i> Listeye Dön</a>}

                        {!itemPinning &&
                            <>
                                    <a onClick={() => saveEducationSorting()} className="btn btn-secondary mx-1"><i
                                        className={"fa fa-check mx-1"}></i> Sıralamayı Kaydet</a>
                                <a onClick={() => setPageContentModalState(true)} className="btn btn-secondary mx-1"><i
                                    className={"fa fa-pencil"}></i> Bölüm Başlıkları ve Özelliklerini Düzenle</a>
                            </>
                        }
                    </div>
                    {itemPinning &&
                    <div className="col-12 d-flex">
                        <Search onSearch={onSearch}></Search>
                    </div>
                    }

                    {itemPinning &&
                    <div className="col-12 mt-4 mb-10">
                        <table className="table management__table">
                            <thead>
                            <tr>
                                <th>Eğitim Başlığı</th>
                                <th>Operasyonlar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                searchData.result.map(d => {
                                    return (
                                        <tr key={d.id}>
                                            <td>
                                                {d.title}
                                            </td>
                                            <td className="text-right">
                                                {data.filter(cd => cd.id === d.id).length === 0 ?
                                                <a onClick={() => pinItem(d)}
                                                   className="btn btn-outline-success btn-sm mx-1 my-1">Pinle</a>
                                                    :
                                                    <a onClick={() => null}
                                                       className="btn btn-outline-danger btn-sm mx-1 my-1">Nesne Zaten Pinlenmiş</a>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            </tbody>
                        </table>
                    </div>
                    }

                    {!itemPinning &&
                    <table className="table management__table">
                        <thead>
                        <tr>
                            <th>Başlık</th>
                            <th className={"text-right"}>Operasyonlar</th>
                        </tr>
                        </thead>
                        {data !== null &&
                            <ReactSortable list={data} setList={setData} tag={"tbody"}>
                                {
                                    data.map((d,index) => {
                                        return (
                                            <tr key={d.id} draggable={true}>
                                                <td>
                                                    {d.title}
                                                </td>
                                                <td className="text-right">
                                                    <a onClick={() => {
                                                        deleteDialog(index)
                                                    }} className="btn btn-outline-danger btn-sm mx-1 my-1">Pinini Kaldır</a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </ReactSortable>
                        }
                    </table>
                    }
                </div>
            }


            {pageContentModalState &&
                <EducationsPageContent data={data.pageContent}
                                       onClose={() => onEducationEditorClose(false)}></EducationsPageContent>
            }
        </div>
    )
}

export default Educations