import React from "react";
import OwlCarousel from "react-owl-carousel";
import PromoteLeftSectionItem from "./PromoteLeftSectionItem/PromoteLeftSectionItem";
import PromoteRightSectionItem from "./PromoteRightSectionItem/PromoteRightSectionItem";
import PromoteBottomSectionItem from "./PromoteBottomSectionItem/PromoteBottomSectionItem";

function Promotes({data}) {
    const pageContent = JSON.parse(data.pageContent);
    return (
        <div className={"page-wrapper py-5"}>
            <div className="sc_title sc_title_default mb-5">
                <div className="sc_item_title_wrap sc_align_center sc_position_below sc_subtitle_present">
                    <h2 className="sc_item_title sc_title_title sc_item_title_style_default">
                        <span className="sc_item_title_line_left"></span><span
                        className="sc_item_title_text">{pageContent.header}</span><span
                        className="sc_item_title_line_right"></span>
                    </h2>
                </div>
                <span
                    className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_below sc_item_title_style_default">{pageContent.title}</span>
            </div>
            <div className="container">
                <div className={"row d-flex"}>
                    <div className="col-12 col-md-8">
                                    <div className="sc_blogger">
                                        {data.promotes.filter(p => p.position === "left").map((p, index) => {
                                            return <PromoteLeftSectionItem key={index} data={p}/>
                                        })}
                                    </div>
                    </div>
                    <div
                        className="col-12 col-md-4 skocak-top-column skocak-element skocak-element-fa9af31 skocak-sidebar sc_column_fixed sc_inner_width_none sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static">
                        <div className="skocak-widget-wrap skocak-element-populated">
                            <div
                                className="skocak-element sc_fly_static skocak-widget skocak-widget-trx_sc_blogger">
                                <OwlCarousel
                                    className="owl-carousel owl-theme"
                                    loop={true}
                                    nav={false}
                                    dots={true}
                                    autoplay={true}
                                    items={1}
                                    autoplayTimeout={3000}>
                                    {data.promotes.filter(p => p.position === "right").map((p, index) => {
                                        return <PromoteRightSectionItem key={index} data={p}/>
                                    })
                                    }
                                </OwlCarousel>
                            </div>
                            <div
                                className="skocak-widget-trx_sc_blogger mt-2 pt-5">
                                <div className="skocak-widget-container">
                                    <div
                                        className="sc_blogger pt-0">
                                        <h2 className="sc_item_title">{pageContent.footer}</h2>
                                        <div className="sc_blogger_content">
                                            {data.promotes.filter(p => p.position === "bottom").map((p, index) => {
                                                return <PromoteBottomSectionItem key={index} data={p}/>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promotes