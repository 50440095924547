import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import "./EditorBoxView.css"
import FontPicker from "../../FontPicker/FontPicker";
import TextEditor from "../../TextEditor/TextEditor";
import ElementBoxView from "../../../../../Pages/PageElements/ElementBoxView/ElementBoxView";
import BaseService from "../../../../../Services/BaseService";
import {useConfig} from "../../../../../Services/ConfigContext";

const EditorBoxView = (props) => {

    const [zoomRatio, setZoomRatio] = useState(false);
    const divWidthRef = useRef();
    const containerWidth = 1200;

    const config = useConfig();

    const [form, setForm] = useState({
        boxes: [],
        layoutColsDesktop: 3, // 2,3,4
        layoutColsMobile: 6, // 6,12,
        overFlowOption: 'center' //center,flex-start
    });

    const [editingBoxIndex, setEditingBoxIndex] = useState(null);
    const [editingBoxIcon, setEditingBoxIcon] = useState("");
    const [editingBoxTitle, setEditingBoxTitle] = useState("");
    const [editingBoxContent, setEditingBoxContent] = useState("");
    const [editingBoxImage, setEditingBoxImage] = useState("");
    const [editingBoxLinkUrl, setEditingBoxLinkUrl] = useState("");
    const [editingBoxLinkText, setEditingBoxLinkText] = useState("");


    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / containerWidth);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);


    const layoutColsDesktopOptions = [
        {value: 2, label: '%16.5'},
        {value: 3, label: '%25'},
        {value: 4, label: '%33'}
    ]

    const layoutColsMobileOptions = [
        {value: 4, label: '%33'},
        {value: 6, label: '%50'},
        {value: 12, label: '%100'}
    ]

    const overFlowOptions = [
        {value: "center", label: 'Ortala'},
        {value: "flex-start", label: 'Sola Yasla'},
    ]


    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }

    useEffect(() => {
        if (props.hasOwnProperty("data")) {
            setForm({...form, ...props.data});
        }
    }, [props]);

    const editBox = (index) => {
        setEditingBoxTitle(form.boxes[index].title);
        setEditingBoxContent(form.boxes[index].content);
        setEditingBoxImage(form.boxes[index].image);
        setEditingBoxIcon(form.boxes[index].icon);
        setEditingBoxLinkUrl(form.boxes[index].linkUrl);
        setEditingBoxLinkText(form.boxes[index].linkText);
        setEditingBoxIndex(index);
    }

    const addBox = () => {
        setEditingBoxIndex(-1);
        setEditingBoxTitle('');
        setEditingBoxContent('');
        setEditingBoxImage('');
        setEditingBoxIcon('');
        setEditingBoxLinkUrl('');
        setEditingBoxLinkText('');
    }

    const deleteBox = (index) => {
        setForm(prevForm => {
            const updatedBoxes = prevForm.boxes.filter((_, i) => i !== index);
            return {...prevForm, boxes: updatedBoxes};
        });
        setEditingBoxIndex(null);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const fileType = selectedFile.type;
            const validTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];

            if (validTypes.includes(fileType)) {
                var baseService = new BaseService();
                baseService.uploadImage(selectedFile).then(response => {
                    setEditingBoxImage(response.data);
                })
            }
        }
    };
    const saveBox = () => {
        setForm(prevForm => {
            const updatedBoxes = editingBoxIndex > -1
                ? prevForm.boxes.map((box, i) => i === editingBoxIndex ? {
                    title: editingBoxTitle,
                    content: editingBoxContent,
                    icon: editingBoxIcon,
                    linkUrl: editingBoxLinkUrl,
                    linkText: editingBoxLinkText,
                    image: editingBoxImage
                } : box)
                : [...prevForm.boxes, {
                    title: editingBoxTitle,
                    content: editingBoxContent,
                    icon: editingBoxIcon,
                    linkUrl: editingBoxLinkUrl,
                    linkText: editingBoxLinkText,
                    image: editingBoxImage
                }];

            return {...prevForm, boxes: updatedBoxes};
        });
        setEditingBoxIndex(null);
    };
    return (
        <div>
            <div className={"row mb-30"}>
                <div className={"col-md-12"} ref={divWidthRef}>
                    <label className={"form-label"}>Önizleme</label>
                </div>
                <div className={"col-md-12"} style={{zoom: zoomRatio}}>
                    <ElementBoxView data={form}></ElementBoxView>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Kutu Düzeni (Masaüstü)</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={layoutColsDesktopOptions.find(c => c.value === form.layoutColsDesktop)}
                                onChange={(selectedOption) => handleForm("layoutColsDesktop", selectedOption.value)}
                                options={layoutColsDesktopOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
                <div className={"col-md-4"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Kutu Düzeni (Mobil)</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={layoutColsMobileOptions.find(c => c.value === form.layoutColsMobile)}
                                onChange={(selectedOption) => handleForm("layoutColsMobile", selectedOption.value)}
                                options={layoutColsMobileOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
                <div className={"col-md-4"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Artan Kutuları</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={overFlowOptions.find(c => c.value === form.overFlowOption)}
                                onChange={(selectedOption) => handleForm("overFlowOption", selectedOption.value)}
                                options={overFlowOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
            </div>

            <div className={"text-right mt-4"}>
                <a className="btn btn-secondary" onClick={() => addBox()}><i
                    className={"fa fa-plus mx-1"}></i> Yeni Ekle</a>
            </div>
            <table className={"table management__table mt-4"}>
                <thead>
                <tr>
                    <th>Nesne Başlığı</th>
                    <th>Operasyonlar</th>
                </tr>
                </thead>
                <tbody>
                {form.boxes.map((b, index) => {
                        return (
                            <tr key={index}>
                                <td>{b.title}</td>
                                <td className="text-right">
                                    <a onClick={() => editBox(index)}
                                       className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                    <a onClick={() => {
                                        deleteBox(index)
                                    }} className="btn btn-outline-danger btn-sm mx-1 my-1">Sil</a>
                                </td>
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>


            <div className={"modal-footer"}>
                <div className={"col-md-12 text-right"}>
                    <a className="btn btn-secondary mx-2" onClick={() => props.closeElementEditor()}><i
                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                    <a className="btn btn-secondary" onClick={() => props.saveElement(form)}><i
                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                </div>
            </div>


            {
                editingBoxIndex !== null &&
                <div className={"modal"} id="staticBackdrop">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Kutu {editingBoxIndex === -1 ? 'Ekle' : 'Düzenle'}</h5>
                                <button type="button" className="btn-close"
                                        onClick={() => setEditingBoxIndex(null)}></button>
                            </div>
                            <div className="modal-body">

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Başlık</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <input className="form-control" value={editingBoxTitle}
                                           onChange={(e) => setEditingBoxTitle(e.target.value)}/>
                                </div>

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>İçerik</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <TextEditor height={200} onEditorChange={(e) => setEditingBoxContent(e)}
                                                initialValue={editingBoxContent}></TextEditor>
                                </div>

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Icon</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <FontPicker onChange={(e) => setEditingBoxIcon(e)} value={editingBoxIcon}/>

                                </div>

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Bağlantı Başlığı</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <input className="form-control" value={editingBoxLinkText}
                                           onChange={(e) => setEditingBoxLinkText(e.target.value)}/>
                                </div>

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Bağlantı Adresi</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <input className="form-control" value={editingBoxLinkUrl}
                                           onChange={(e) => setEditingBoxLinkUrl(e.target.value)}/>
                                </div>


                                <div className={"col-md-12"}>
                                    <label className={"form-label"}>Fotoğraf</label>
                                </div>

                                <div className={"col-md-12"}>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        onChange={(e) => handleFileChange(e)}
                                        accept=".png, .jpg, .jpeg, .webp"
                                    />
                                </div>

                                <div className={"col-md-12 text-right mt-4"}>
                                    <a className="btn btn-secondary mx-2" onClick={() => setEditingBoxIndex(null)}><i
                                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                                    <a className="btn btn-secondary" onClick={() => saveBox()}><i
                                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditorBoxView;