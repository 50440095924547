import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import "./EducationDetail.css"
import OwlCarousel from "react-owl-carousel";
import {useConfig} from "../../../Services/ConfigContext";
import HomePageService from "../../../Services/HomePageService";
import EducationItem from "../../HomePage/Educations/EducationItem/EducationItem";
import {Helmet} from "react-helmet-async";

const EducationDetail = (props) => {

    const pageUrl = useParams()["*"];

    const [data, setData] = useState({
        title: '',
        categoryTitle: '',
        description: '',
        information: '',
        price: '',
        likUrl: '',

    });

    const [relatedEducations, setRelatedEducations] = useState([]);
    const [photos, setPhotos] = useState([]);

    const config = useConfig();

    useEffect(() => {
        if (props.data && props.photos) {
            setData(props.data);
            setPhotos(props.photos);
        } else {
            HomePageService.getEducation(pageUrl).then(response => {
                setData(response.data.education);
                setPhotos(JSON.parse(response.data.education.background));
                setRelatedEducations(response.data.relatedEducations);
            });
        }
    }, [props]);


    const bannerData = config.banner;

    return (
        <div className="container mb-5">
            {bannerData &&  <Helmet>
                <title>{bannerData.metaTitle} - {data.title}</title>
                <meta name="description" content={data.information}/>
            </Helmet>
            }
            <div className={"row"}>
                <div className={"col-md-5"}>
                    {photos.length > 0 &&
                        <OwlCarousel
                            className="owl-carousel owl-theme"
                            loop={true}
                            nav={false}
                            dots={true}
                            autoplay={false}
                            margin={10}
                            items={1}
                            autoplayTimeout={5000}>
                            {photos.map((p, index) => {
                                return <div key={index} className="sc_blogger_item_body">
                                    <div
                                        className="post_featured with_thumb hover_inherit sc_item_featured sc_blogger_item_featured post_featured_bg mb-0"
                                        data-ratio="1:1">
                                        <div className="featured_bg_wrapper">
                                            <div className="featured_bg"
                                                 style={{backgroundImage: 'url(' + config.imagesPath + p + ')'}}></div>
                                        </div>
                                        <div className="mask"></div>
                                    </div>
                                </div>
                            })
                            }
                        </OwlCarousel>
                    }

                </div>
                <div className={"col-md-7 mt-2 mt-md-0"}>
                    <div className="summary entry-summary">
                        <h1 className="education_title entry-title mb-0">{data.title}</h1>
                        <div className={"mb-4"}><small>{data.categoryTitle}</small></div>
                        {data.price.length > 0 &&
                            <p className="education_price mb-2">{data.price} ₺</p>
                        }
                        <p className={"education_description mb-4"}
                           dangerouslySetInnerHTML={{__html: data.information}}></p>


                        <a href={data.linkUrl} className="btn buy-btn">SATIN AL</a>
                    </div>

                </div>
                <div className={"col-md-12 mt-4"}>
                    <span className={"description-title"}>Eğitim Detayları</span>
                    <div className={"description-description mt-4"}
                         dangerouslySetInnerHTML={{__html: data.description}}></div>
                </div>

            </div>
            {relatedEducations.length > 0 &&
                <>
                    <div className="sc_title sc_title_default mb-4 sticky-top position-sticky shop-title-holder mt-4 mt-mb-0">
                        <div className="sc_item_title_wrap sc_align_center sc_position_below sc_subtitle_present"><h2
                            className="sc_item_title sc_title_title sc_item_title_style_default"><span
                            className="sc_item_title_line_left"></span><span
                            className="sc_item_title_text">Benzer Eğitimler</span><span
                            className="sc_item_title_line_right"></span></h2></div>
                    </div>
                    <div className={"row"}>
                        {relatedEducations.map((rp, index) => {
                            return <div key={index} className={"col-md-3 mb-4"}><EducationItem data={rp}/></div>
                        })}
                    </div>
                </>
            }

        </div>
    )
}

export default EducationDetail;