import * as React from 'react';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import "./FontPicker.css"


const FontPicker = ({onChange, value}) => {
    const iconList = Object.keys(solidIcons)
        .filter((key) => key.startsWith('fa'))
        .map((iconKey) => {
            return {
                value: "fas fa-" + solidIcons[iconKey].iconName,
                label: <i className={'fas fa-' + solidIcons[iconKey].iconName} />,
            };
        });


    return (
        <>
            <Select
                className={"item-picker-select"}
                style={{zIndex : 3}}
                placeholder={"Icon"}
                value={ value ? iconList.find(i => i.value === value) : null}
                isSearchable={true}
                isMulti={false}
                formatOptionLabel={(data) => (
                    <div className="icon-option">{data.label}</div>
                )}
                options={iconList}
                classNamePrefix="form-control-select"
                onChange={(selectedOption) => onChange( selectedOption.value)}
            />
        </>
    );
}

export default FontPicker;
