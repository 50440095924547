import "../../Management/Management.css"
import toast, {Toaster} from "react-hot-toast";
import {useEffect, useState} from "react";
import ManagementUsersService from "../../Services/ManagementUsersService";
import { useAuth} from "../../Services/AuthContext";
import {useNavigate} from "react-router-dom";

const ManagementLogin = () => {

    const navigate = useNavigate();


    const { loggedUser,setToken } = useAuth();

    useEffect(() =>{
        if(loggedUser){
            navigate('/management/users')
        }
    },[loggedUser])

    const [user, setUser] = useState({
        'username': '',
        'password': ''
    });

    const initialErrorContainer = {
        username: true,
        password: true,
    };
    const submitForm = () => {
        setFormSubmitted(true);
        if (isFormValid()) {
            setLoaderEnabled(true);
            ManagementUsersService.login(user)
                .then(response => {
                    toast.success("Giriş yapıldı");
                    let token = response.data.token;
                    setToken(token);
                }).catch(error => {
                toast.error(error?.response.data)
            }).finally(() => {
                setLoaderEnabled(false);
            })
        }
    }

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [loaderEnabled, setLoaderEnabled] = useState(false)
    const [error, setError] = useState(initialErrorContainer)
    const isFormValid = () => {
        return !(Object.values(error).some(value => value === true));
    }

    useEffect(() => {
        const checkErrors = {
            username: user.username.length<2,
            password: user.password.length < 8
        };
        setError({...error, ...checkErrors});
    }, [user]);

    const handleForm = (name, value) => {
        setUser({...user, [name]: value});
    }

    return (
        <div className={"row"}>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div className="container">
                    <div className="page-header__content">
                        <h2 className="page-header__title">Giriş Yap</h2>
                    </div>
                </div>
            </section>
            <section className="pt-5 section-space-bottom2 col-xs-12 col-sm-8 col-md-4 m-auto">
                <div className="container">
                    <div className="row gutter-y-40">
                        <form className="contact__form contact-form-validated" id="getOfferForm">
                            <div className="contact__form__group">
                                <div className="contact__form__control contact__form__control--full position-relative">
                                    <input required={true} type="text"
                                           className={"contact-input mb-4 w-100"}
                                           disabled={loaderEnabled}
                                           value={user?.username}
                                           onChange={(e) => handleForm('username', e.target.value)}
                                           placeholder="E-posta Adresi *"/>
                                    {(error.username && formSubmitted) &&
                                        <label className="error w-100">Lütfen e-posta adresinizi giriniz.</label>}
                                </div>
                                <div className="contact__form__control contact__form__control--full position-relative">
                                    <input required={true} type="password" value={user?.password} className={"contact-input w-100 mb-4"}
                                           disabled={loaderEnabled}
                                           onChange={(e) => handleForm('password', e.target.value)}
                                           placeholder="Şifre *"/>
                                    {(error.password && formSubmitted) &&
                                        <label className="error w-100">Lütfen parolanızı giriniz</label>}
                                </div>
                                <div className="contact__form__control contact__form__control--full">
                                    <a  onClick={() => submitForm()} className="sc_button sc_button_default sc_button_size_small my-auto"><span className="sc_button_title">Giriş Yap</span></a>


                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </section>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>)
}

export default ManagementLogin