import "./ElementFeature.css"
import {useEffect, useState} from "react";
import React from 'react';

const ElementFeature = (props) => {

    const [data, setData] = useState({
        features: [],
        layoutColsDesktop: 3, // 2,3,4
        layoutColsMobile: 4, // 4,6,12,
        overFlowOption : 'center' //center,flex-start
    })

    useEffect(() => {
        if (props) {
            setData({...data, ...props.data})
        }
    }, [props]);

    return (

                <div className="funfact__inner">
                    <div className={"funfact__row  d-flex w-100 row-overflow-"+data.overFlowOption}>
                        {
                            data.features.map((f, index) => {
                                return (
                                    <div key={index}
                                         className={"col-lg-" + data.layoutColsDesktop + " col-xs-" + data.layoutColsMobile + " wow fadeInUp animated"}>
                                        <div className="funfact__item funfact__item--1">
                                            <div className="funfact__icon-box">
                                            <span className="funfact__icon"><i
                                                className={"fa fa-" + f.icon}></i></span>
                                            </div>
                                            <div className="funfact__content">
                                                <h3 className="funfact__count count-box counted">{f.title}</h3>
                                                <p className="funfact__text"
                                                   dangerouslySetInnerHTML={{__html: f.content}}></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
    )
}

export default ElementFeature