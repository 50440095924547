import React, {useEffect, useState} from "react";
import Select from "react-select";
import ManagementUsersService from "../../../Services/ManagementUsersService";
import toast from "react-hot-toast";

const UserEdit = (props) => {

    const roles = [
        {label: 'Yönetici', value: 'Administrator'},
        {label: 'Editör', value: 'Editor'},
        {label: 'İletişim', value: 'Contact'},
    ]

    const [password2, setPassword2] = useState(null);

    const [user, setUser] = useState({
        'id': null,
        'name': '',
        'email': '',
        'password': '',
        'roles': [],
    });

    const initialErrorContainer = {
        name: true,
        email: true,
        password2: user.id === null,
        password: user.id === null,
        roles: true
    };
    const submitForm = () => {
        setFormSubmitted(true);
        if (isFormValid()) {
            setLoaderEnabled(true);
            (user.id ? ManagementUsersService.updateUser(user.id, user) : ManagementUsersService.saveUser(user))
                .then(response => {
                    toast.success("Kullanıcı kaydedildi")
                    props.onClose();
                }).catch(error => {
                toast.error(error.message)
            }).finally(() => {
                setLoaderEnabled(false);
            })
        }
    }

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [loaderEnabled, setLoaderEnabled] = useState(false)
    const [error, setError] = useState(initialErrorContainer)
    const isFormValid = () => {
        return !(Object.values(error).some(value => value === true));
    }

    useEffect(() => {
        const checkErrors = {
            email: !user.email.match(/.+@.+/),
            name: user.name.length < 2,
            password: (user.hasOwnProperty('password') && user.password.length < 8 && user.id === null),
            password2: (password2 !== user?.password && user.id === null),
            roles: user.roles.length < 1
        };
        setError({...error, ...checkErrors});
    }, [user, password2]);

    useEffect(() => {
        if (props.user) {
            setUser(props.user);
        }
    }, [props.user]);

    const handleForm = (name, value) => {
        setUser({...user, [name]: value});
    }

    const handleRole = (selectedRoles) => {
        const selectedValues = selectedRoles ? selectedRoles.map(role => role.value) : [];
        setUser({...user, roles: selectedValues});
    }

    return (
        <div className={"modal"} id="staticBackdrop">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{user?.id ? 'Kullanıcı Düzenle' : 'Kullanıcı Ekle'}</h5>
                        <button type="button" className="btn-close" onClick={() => props.onClose()}></button>
                    </div>
                    <div className="modal-body">
                        <div className={"col-md-12 d-flex mt-2"}>
                            <label className={"form-label col-3 text-left my-auto"}>İsim Soyisim</label>
                            <div className={"col-9 m-auto"}>
                                <input className="form-control" required={true} type="text"
                                       disabled={loaderEnabled}
                                       value={user?.name}
                                       onChange={(e) => handleForm('name', e.target.value)}
                                       placeholder="İsim Soyisim *"/>
                            </div>
                        </div>

                        {(error.name && formSubmitted) &&
                            <div className={"row"}>
                                <label className="error">Lütfen kullanıcı için ad soyad bilgisi giriniz.</label>
                            </div>
                        }

                        <div className={"col-md-12 d-flex mt-2"}>
                            <label className={"form-label col-3 text-left my-auto"}>E-posta Adresi</label>
                            <div className={"col-9 m-auto"}>
                                <input className="form-control" required={true} type="email"
                                       disabled={loaderEnabled}
                                       value={user?.email}
                                       onChange={(e) => handleForm('email', e.target.value)}
                                       placeholder="E-posta Adresi *"/>
                            </div>
                        </div>
                        {(error.email && formSubmitted) &&
                            <div className={"row"}>
                                <label className="error">Lütfen geçerli bir e-posta adresi giriniz.</label>
                            </div>
                        }

                        {
                            user.id === null &&
                            <>

                                <div className={"col-md-12 d-flex mt-2"}>
                                    <label className={"form-label col-3 text-left my-auto"}>Şifre</label>
                                    <div className={"col-9 m-auto"}>
                                        <input className="form-control" required={true} type="password"
                                               value={user?.password}
                                               disabled={loaderEnabled}
                                               onChange={(e) => handleForm('password', e.target.value)}
                                               placeholder="Şifre *"/>
                                    </div>

                                </div>
                                {(error.password && formSubmitted) &&
                                    <div className={"row"}>
                                        <label className="error">Parola en az 8 karakterden
                                            oluşmalıdır.</label>
                                    </div>
                                }

                                <div className={"col-md-12 d-flex mt-2"}>
                                    <label className={"form-label col-3 text-left my-auto"}>Şifre (Tekrar)</label>
                                    <div className={"col-9 m-auto"}>
                                        <input className="form-control" required={true} type="password"
                                               disabled={loaderEnabled}
                                               onChange={(e) => setPassword2(e.target.value)}
                                               placeholder="Şifre (Tekrar) *"/>
                                    </div>

                                </div>
                                {(error.password2 && formSubmitted) &&
                                    <div className={"row"}>
                                        <label className="error">Parolalar eşleşmiyor eşleşmiyor.</label>
                                    </div>
                                }

                            </>

                        }


                        <div className={"col-md-12 d-flex mt-2"}>
                            <label className={"form-label col-3 text-left my-auto"}>Rol</label>
                            <div className={"col-9 m-auto"}>
                                <Select isSearchable={false} required={true}
                                        isMulti={true}
                                        isDisabled={loaderEnabled}
                                        value={roles.filter(option => user.roles.includes(option.value))}
                                        onChange={(selectedOption) => handleRole(selectedOption)}
                                        options={roles}
                                        classNamePrefix="form-control-select"/>
                            </div>
                        </div>
                        {(error.roles && formSubmitted) &&
                            <div className={"row"}>
                                <label className="error">Lütfen rol seçiniz.</label>
                            </div>
                        }

                    </div>
                    <div className="modal-footer">
                        <a onClick={() => submitForm()}
                           className={"btn text-center " + (loaderEnabled ? 'disabledElement' : '')}
                           style={{float: 'right'}}>
                            <span>Kaydet</span>
                        </a>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default UserEdit