import {useEffect, useState} from "react";
import Select from "react-select";
import ManagementUsersService from "../../../Services/ManagementUsersService";
import toast from "react-hot-toast";

const UserPasswordChange = (props) => {


    const [password2, setPassword2] = useState(null);

    const [user, setUser] = useState({
        'id': null,
        'password': ''
    });

    const initialErrorContainer = {
        password2: user.id === null,
        password: user.id === null
    };
    const submitForm = () => {
        setFormSubmitted(true);
        if (isFormValid()) {
            setLoaderEnabled(true);
            ManagementUsersService.changePassword(user.id, user.password)
                .then(response => {
                    toast.success("Kullanıcı kaydedildi")
                    props.onClose();
                }).catch(error => {
                toast.error(error?.response.data)
            }).finally(() => {
                setLoaderEnabled(false);
            })
        }
    }

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [loaderEnabled, setLoaderEnabled] = useState(false)
    const [error, setError] = useState(initialErrorContainer)
    const isFormValid = () => {
        return !(Object.values(error).some(value => value === true));
    }

    useEffect(() => {
        const checkErrors = {
            password: user.password.length < 8,
            password2: password2 !== user?.password
        };
        setError({...error, ...checkErrors});
    }, [user, password2]);

    useEffect(() => {
        if (props.user) {
            setUser({...user, id: props.user});
        }
    }, []);

    const handleForm = (name, value) => {
        setUser({...user, [name]: value});
    }

    return (
        <div className={"modal"} id="staticBackdrop">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Şifre Değiştir</h5>
                        <button type="button" className="btn-close" onClick={() => props.onClose()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="personalInformations">
                            <form className="contact__form contact-form-validated" id="getOfferForm">

                                <div className="contact__form__group">

                                    <div className="contact__form__control contact__form__control--full">
                                        <input required={true} type="password" value={user?.password}
                                               disabled={loaderEnabled}
                                               onChange={(e) => handleForm('password', e.target.value)}
                                               placeholder="Şifre *"/>
                                        {(error.password && formSubmitted) &&
                                            <label className="error">Parola en az 8 karakterden
                                                oluşmalıdır.</label>}
                                    </div>
                                    <div className="contact__form__control contact__form__control--full">
                                        <input required={true} type="password"
                                               disabled={loaderEnabled}
                                               onChange={(e) => setPassword2(e.target.value)}
                                               placeholder="Şifre (Tekrar) *"/>
                                        {(error.password2 && formSubmitted) &&
                                            <label className="error">Parolalar eşleşmiyor eşleşmiyor.</label>}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a onClick={() => submitForm()}
                           className={"acn-theme-btn " + (loaderEnabled ? 'disabledElement' : '')}
                           style={{float: 'right'}}>
                            <span>Kaydet</span>
                            <span className="acn-theme-btn__icon-box">
                                <span className="acn-theme-btn__icon">
                                    {loaderEnabled && <span className={"loader"}></span>}
                                    {!loaderEnabled && <i className="fas fa-save"></i>}
                            </span>
                                </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPasswordChange