import {useEffect, useState} from "react";
const FooterEdit = (props) => {

    const [form,setForm] = useState({
        parent : null,
        name : null,
        url : null,
        id : null
    })

    const submitForm = () => {
        setFormSubmitted(true);
        if (isFormValid()) {
            props.onModalSave(form)
        }
    }

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [error, setError] = useState({
        name : true,
        url : true,
    })
    const isFormValid = () => {
        return !(Object.values(error).some(value => value === true));
    }

    useEffect(() => {
        const checkErrors = {
            name : form.name?.length < 1,
            url : form.url?.length < 1
        };
        setError({...error, ...checkErrors});
    }, [form]);

    useEffect(() => {
        if (props.item) {
            setForm(props.item);
        }
    }, [props.item]);

    const handleForm = (name, value) => {
        setForm({...form, [name]: value});
    }

    return (
        form.id &&
        <div className={"modal"} id="staticBackdrop">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Menü Düzenle</h5>
                        <button type="button" className="btn-close" onClick={() => props.onModalClose()}></button>
                    </div>
                    <div className="modal-body">
                        <form className="contact__form contact-form-validated" id="getOfferForm">
                            <div className="personalInformations">
                                <div className="contact__form__group">
                                    <div className="contact__form__control contact__form__control--full">
                                        <input required={true} type="text"  className={"contact-input w-100 mb-4"}
                                               value={form?.name}
                                               onChange={(e) => handleForm('name', e.target.value)}
                                               placeholder="Başlık *"/>
                                        {(error.name && formSubmitted) &&
                                            <label className="error">Lütfen menü için başlık giriniz.</label>}
                                    </div>
                                    <div className="contact__form__control contact__form__control--full">
                                        <input required={true} type="text"  className={"contact-input w-100 mb-4"}
                                               value={form?.url}
                                               onChange={(e) => handleForm('url', e.target.value)}
                                               placeholder="URL *"/>
                                        {(error.url && formSubmitted) &&
                                            <label className="error">Lütfen menü için URL giriniz.</label>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <div className="contact__form__control contact__form__control--full">
                            <a  onClick={() => submitForm()} className="sc_button sc_button_default sc_button_size_small my-auto"><span className="sc_button_title">Kaydet</span></a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterEdit