import Search from "../Layout/Search/Search";
import Paginator from "../Layout/Paginator/Paginator";
import {useEffect, useState} from "react";
import EducationEdit from "./EducationEdit/EducationEdit";
import swal from "sweetalert2";
import toast from "react-hot-toast";
import ManagementEducationsService from "../../Services/ManagementEducationsService";

const EducationsManagement = () => {

    const [educationEditModalState, setEducationEditModalState] = useState(false);
    const [editingEducation, setEditingEducation] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [pageValue, setPageValue] = useState(1);
    const [data, setData] = useState({
        total: 0,
        result: []
    });

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const onPageChange = (value) => {
        setPageValue(value);
    }

    const editorOnClose = () => {
        getData();
        setEducationEditModalState(false);
    }

    const editEducation = (id = null) => {
        setEditingEducation(id);
        setEducationEditModalState(true);
    }

    const deleteDialog = (education) => {
        swal.fire({
            title: "İşleme devam et",
            html: "<b>" + education.title + "</b> başlıklı eğitim silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                ManagementEducationsService.deleteEducation(education.id).then(response => {
                    toast.success('Eğitim silindi');
                    getData();
                }).catch(e => {
                    toast.error(e.response.data);
                })
            }
        })
    }

    const getData = () => {
        ManagementEducationsService.getEducations(searchValue, pageValue).then(response => {
            setData(response.data);
        });
    }

    useEffect(() => {
        getData();
    }, [searchValue, pageValue]);

    return (
        <>
            {!educationEditModalState &&
                <div className="row">
                    <ul className="acn-theme-breadcrumb list-unstyled">
                        <li><a href="/management">Yönetim Paneli</a></li>
                        <li><span>Eğitim Yönetimi</span></li>
                    </ul>


                    <div className="col-12 d-flex">
                        <div className="col-10 pr-20">
                            <Search onSearch={onSearch}></Search>
                        </div>
                        <div className="col-2 d-flex">
                            <a className="btn text-center px-3 py-2 mx-auto mr-0" onClick={() => editEducation(-1)}>Eğitim
                                Ekle</a>
                        </div>
                    </div>
                    <div className="col-12 mt-4 mb-10">
                        <table className="table management__table">
                            <thead>
                            <tr>
                                <th>Eğitim Başlığı</th>
                                <th>Fiyat</th>
                                <th>Site İçi Bağlantısı</th>
                                <th>Satın Alma Bağlantısı</th>
                                <th>Operasyonlar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.result.map(d => {
                                    return (
                                        <tr key={d.id}>
                                            <td>
                                                {d.title}
                                            </td>
                                            <td width={100}>
                                                {d.price}
                                            </td>
                                            <td>
                                                {window.location.protocol + '//' + window.location.host + '/magaza/'+d.pageUrl}
                                            </td>
                                            <td>
                                                {d.linkUrl}
                                            </td>
                                            <td className="text-right">
                                                <a onClick={() => editEducation(d.id)}
                                                   className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                                <a onClick={() => {
                                                    deleteDialog(d)
                                                }} className="btn btn-outline-danger btn-sm mx-1 my-1">Eğitimü Sil</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                            </tbody>
                        </table>
                    </div>
                    <Paginator totalResult={data.total} currentPage={pageValue} onPageChange={onPageChange}></Paginator>

                </div>
            }

            {
                educationEditModalState &&
                <EducationEdit onClose={editorOnClose} educationId={editingEducation}></EducationEdit>
            }
        </>
    )
}

export default EducationsManagement