import BaseService from './BaseService';

class ManagementProductsService extends BaseService {

    getProducts(search,page) {
        return this.apiClient.get('HomePageManagement/Products?search='+search+'&page='+page);
    }
    saveProduct(product) {
        return this.apiClient.put('HomePageManagement/Product',product);
    }
    getProduct(id){
        return this.apiClient.get('HomePageManagement/Product/'+id);
    }
    deleteProduct(id){
        return this.apiClient.delete('/HomePageManagement/Product/'+id);
    }
}

export default new ManagementProductsService();