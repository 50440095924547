import BaseService from './BaseService';

class ManagementUsersService extends BaseService {

    getUsers(search,page) {
        return this.apiClient.get('IdentityManagement/User?search='+search+'&page='+page);
    }
    saveUser(user) {
        return this.apiClient.post('IdentityManagement/User',user);
    }
    updateUser(userId,user) {
        return this.apiClient.put('IdentityManagement/User/'+userId,user);
    }
    changePassword(userId,password) {
        return this.apiClient.put('IdentityManagement/Password/'+userId, {password : password});
    }
    getUser(id){
        return this.apiClient.get('IdentityManagement/User/'+id);
    }
    deleteUser(id){
        return this.apiClient.delete('/IdentityManagement/User/'+id);
    }

    login(user){
        return this.apiClient.post('Login',user);
    }
}

export default new ManagementUsersService();