import './ContactManagement.css'
import React, {useEffect, useState} from "react";
import swal from "sweetalert2";
import toast from "react-hot-toast";
import ManagementContactService from "../../Services/ManagementContactService";
import ContactPageContent from "./PageContent/ContactPageContent";
import Search from "../Layout/Search/Search";
import Paginator from "../Layout/Paginator/Paginator";
import {formatDate} from "../../Pages/Layout/Utils/DateUtils/DateUtils";
import MessageViewer from "./MessageViewer/MessageViewer";


const ContactManagement = () => {

    const [pageContentModalState, setPageContentModalState] = useState(false);

    const [searchValue, setSearchValue] = useState("");
    const [pageValue, setPageValue] = useState(1);
    const [data, setData] = useState({
        total: 0,
        result: []
    });

    const [activeMessage, setActiveMessage] = useState(null);
    const [activeMessageModalState, setActiveMessageModalState] = useState(false);

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const onPageChange = (value) => {
        setPageValue(value);
    }

    const showMessage = (id) => {
        if (id) {
            ManagementContactService.getMessage(id).then(response => {
                setActiveMessage(response.data);
                setActiveMessageModalState(true);
            })
        }
    }


    const modalOnClose = () => {
        setPageContentModalState(false);
        setActiveMessageModalState(false);
    }
    const deleteDialog = (message) => {
        swal.fire({
            title: "İşleme devam et",
            html: "Mesaj silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                ManagementContactService.deleteMessage(message.id).then(response => {
                    toast.success('Mesaj silindi');
                    setActiveMessageModalState(false);
                    setActiveMessage(false);
                    getData();
                }).catch(e => {
                    toast.error(e.response.data);
                })
            }
        })
    }

    const makeHasRead = (message) => {
        ManagementContactService.changeStatus(message.id,1).then(response => {
            toast.success('Mesaj okundu');
            setActiveMessageModalState(false);
            setActiveMessage(false);
            getData();
        }).catch(e => {
            toast.error(e.response.data);
        })
    }

    const getData = () => {
        ManagementContactService.getMessages(searchValue, pageValue).then(response => {
            setData(response.data);
        });
    }

    useEffect(() => {
        getData();
    }, [searchValue, pageValue]);

    return (


        <div className="row">
            <ul className="acn-theme-breadcrumb list-unstyled">
                <li><a href="/management">Yönetim Paneli</a></li>
                <li><span>İletişim ve Mesajlar</span></li>
            </ul>

            <div className={"col-12 d-flex"}>
                <div className="col-10 pr-20">
                    <Search onSearch={onSearch}></Search>
                </div>

                <div className="col-2 d-flex">
                    <a onClick={() => setPageContentModalState(true)}
                       className="btn text-center px-3 py-2 mx-auto mr-0"><i
                        className={"fa fa-pencil"}></i> Sayfa İçeriği</a>
                </div>
            </div>
            <div className={"col-12 mt-4"}>
                <table className="table management__table">
                    <thead>
                    <tr>
                        <th>Gönderen</th>
                        <th >Tarih</th>
                        <th>Mesaj</th>
                        <th className={"text-center"}>Durum</th>
                        <th>Operasyonlar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.result && data.result.map(d => {
                        return (
                            <tr key={d.id}>
                                <td className={"ellipsis-cell"} style={{maxWidth :150,width : 150}}>
                                    {d.name}
                                </td>
                                <td width={120}>
                                    {formatDate(d.createDate)}
                                </td>
                                <td  className={"message-cell ellipsis-cell"}>
                                    {d.message}
                                </td>
                                <td width={50} className={"text-center"}>
                                    {d.status === 1 ?
                                    <span className={"btn btn-outline-success disabledElement"}><i className={"fa fa-check"}/></span> :
                                    <span className={"btn btn-outline-danger disabledElement"}><i className={"fa fa-hourglass-half"}/></span>
                                    }
                                </td>

                                <td className="text-right"  width={200}>
                                    <a onClick={() => showMessage(d.id)}
                                       className="btn btn-outline-success btn-sm mx-1 my-1 d-inline-block">Görüntüle</a>
                                    <a onClick={() => {
                                        deleteDialog(d)
                                    }} className="btn btn-outline-danger btn-sm mx-1 my-1 d-inline-block">Mesajı Sil</a>
                                </td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
            </div>
            <Paginator totalResult={data.total} currentPage={pageValue} onPageChange={onPageChange}></Paginator>


            {
                activeMessageModalState &&
                <MessageViewer data={activeMessage} onRead={makeHasRead} onDelete={deleteDialog} onClose={modalOnClose}></MessageViewer>
            }

            {
                pageContentModalState &&
                <ContactPageContent onClose={modalOnClose}></ContactPageContent>
            }

        </div>
    )
}

export default ContactManagement