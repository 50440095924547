import FeatureItem from "./FeatureItem/FeatureItem";
import React from "react";

function Features({data}) {
    return (<div className={"page-wrapper sc_blogger alter_bg py-5"}>
            <div className={"container "}>
                <div className="justify-content-evenly row">
                    {
                        data.map((d, index) => {
                            return <div className={"col-12 col-md-3 feature-item-container"} key={index}><FeatureItem data={d}></FeatureItem>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Features