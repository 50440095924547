import BaseService from './BaseService';

class HomePageService extends BaseService {

    getThemeConfig() {
        return this.apiClient.get('Home');
    }
    getHomePage() {
        return this.apiClient.get('Home/Homepage');
    }

    getContacts(){
        return this.apiClient.get('Home/Contact');
    }

    getProducts(search,category,page) {
        return this.apiClient.get('Home/Products?search='+search+'&category='+category+'&page='+page);
    }

    getProduct(pageUrl){
        return this.apiClient.get('Home/Product/'+pageUrl);
    }

    getEducations(search,category,page) {
        return this.apiClient.get('Home/Educations?search='+search+'&category='+category+'&page='+page);
    }

    getEducation(pageUrl){
        return this.apiClient.get('Home/Education/'+pageUrl);
    }

    getBlogList(search,category,page) {
        return this.apiClient.get('Home/Blog?search='+search+'&category='+category+'&page='+page);
    }

    getBlog(url) {
        return this.apiClient.get('Home/Blog/'+url);
    }

    getPage(url) {
        return this.apiClient.get('Home/Page/'+url);
    }

    sendMessage(data) {
        return this.apiClient.post('Home/SendMessage',data);
    }
}

export default new HomePageService();