import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './MainSlider.css'
import React from "react";
import SliderItem from "./SliderItem/SliderItem";

function MainSlider({data}) {

    const pageContent = JSON.parse(data.pageContent);

    return (
        <div className="page-wrapper py-5">
            <div className="sc_title sc_title_default mb-5">
                <div className="sc_item_title_wrap sc_align_center sc_position_below sc_subtitle_present"><h2
                    className="sc_item_title sc_title_title sc_item_title_style_default"><span
                    className="sc_item_title_line_left"></span><span
                    className="sc_item_title_text">{pageContent.header}</span><span
                    className="sc_item_title_line_right"></span></h2></div>

            </div>
            <div className="container">
                {data.sliders.length > 0 &&
                    <div
                        className="row">
                        <OwlCarousel
                            className="main-slider-two__carousel acn-theme-owl__carousel acn-theme-owl__carousel--basic-nav owl-carousel owl-theme"
                            loop={true}
                            smartSpeed={2000}
                            nav={true}
                            dots={false}
                            autoplay={true}
                            autoplayHoverPause={true}

                            autoplayTimeout={3000}
                            navText={[
                                "<i class='fas fa-chevron-left'></i>",
                                "<i class='fas fa-chevron-right'></i>",
                            ]}
                            responsive={{
                                0: {
                                    "items": pageContent.mobileItemCount,
                                    "nav": false,
                                    "dots": false,
                                    "margin": 10
                                },
                                768: {
                                    "items": pageContent.desktopItemCount,
                                    "nav": false,
                                    "dots": false,
                                    "margin": 20
                                }
                        }

                            }>
                            {
                                data.sliders.map((d, index) => {
                                    return (<SliderItem key={index} data={d}/>)

                                })
                            }
                        </OwlCarousel>
                    </div>
                }
            </div>
        </div>
    );
}

export default MainSlider