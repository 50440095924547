import React, {createContext, useContext, useEffect, useState} from 'react';
import HomePageService from "./HomePageService";

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {

    const [themeConfig, setThemeConfig] = useState({
        imagesPath : '/shared-files/images/',
        menu: [],
        footer: [],
        banner: null
    });

    useEffect(() => {
        HomePageService.getThemeConfig().then((response) => {
            const banner = JSON.parse(response.data.banner);
            const newThemeConfig = {...themeConfig, ...{
                    ...response.data,
                    banner: banner
                }};
            setThemeConfig(newThemeConfig);
        });
    },[]);


    return (
        <ConfigContext.Provider value={themeConfig}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => useContext(ConfigContext);