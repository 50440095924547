import {useEffect, useState} from "react";
import toast from "react-hot-toast";
import TextEditor from "../../../Management/Layout/Utils/TextEditor/TextEditor";
import ManagementHomePageService from "../../../Services/ManagementHomePageService";

const ContactPageContent = (props) => {


    const [form, setForm] = useState({
        formText: '',
        description: '',
    })

    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }

    const save = () => {
        ManagementHomePageService.savePageContent("Address",JSON.stringify(form)).then(response => {
            toast.success('İçerik kaydedildi');
            props.onClose()
        })
    }

    useEffect(() => {
        ManagementHomePageService.getPageContent("Address").then(response => {
            let newData = {};
            Object.keys(response.data).forEach((key) => {
                if (form.hasOwnProperty(key)) {
                    newData[key] = response.data[key]
                }
            });
            setForm({...form, ...newData});
        })
    },[]);


    return (
        <div className={"modal modal-lg"} id="staticBackdrop">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Sayfa İçeriği</h5>
                        <button type="button" className="btn-close" onClick={() => props.onClose()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">


                            <div className={"col-md-12 d-flex mt-2"}>
                                <label className={"form-label col-3 text-left my-auto"}>İçerik</label>
                            </div>
                            <div className={"col-md-12 mt-2"}>
                                <TextEditor onEditorChange={(e) => handleForm("description", e)} height={500}
                                            initialValue={form.description}></TextEditor>
                            </div>


                            <div className={"col-12 text-right mt-4"}>
                                <a onClick={() => props.onClose()} className="btn btn-secondary mx-2"><i
                                    className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                                <a onClick={() => save()} className="btn btn-secondary"><i
                                    className={"fa fa-save mx-1"}></i> Kaydet</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactPageContent