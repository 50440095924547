import {useEffect, useRef, useState} from "react";
import ProductItem from "../HomePage/Products/ProductItem/ProductItem";
import HomePageService from "../../Services/HomePageService";
import InfiniteScroll from "react-infinite-scroll-component";
import React from 'react';
import {Helmet} from "react-helmet-async";
import {useConfig} from "../../Services/ConfigContext";

const ProductList = () => {

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState("");

    const page = useRef(1);
    const [totalResults, setTotalResults] = useState(0);
    const [search, setSearch] = useState("");

    let searchTimeout = null;

    const [hasMore, setHasMore] = useState(true);

    const clearFilters = () => {
        setSearch("");
        setActiveCategory("");
        page.current = 1;
    }

    const getData = () => {
        HomePageService.getProducts(search, activeCategory, page.current).then(response => {
            setProducts((oldProducts) => [...oldProducts, ...response.data.products]);
            if (response.data.products.length === 0) {
                setHasMore(false);
            } else {
                setHasMore(true)
            }
            setCategories(response.data.categories);
            setTotalResults(response.data.totalResults);
            page.current = page.current+1;
        })
    }

    const onSearch = (val) => {
        if(searchTimeout){
            clearTimeout(searchTimeout);
        }

        searchTimeout = setTimeout(() => {
            setSearch(val)
        },500);
    }

    useEffect(() => {
        setProducts([]);
        if(page.current > 1) {
            page.current = 1;
        }
        getData();
    }, [activeCategory, search]);


    const config = useConfig();
    const bannerData = config.banner;

    return (<div className="page-wrapper">

        {bannerData &&  <Helmet>
            <title>{bannerData.metaTitle} - Mağaza</title>
            <meta name="description" content={bannerData.metaDescription}/>
        </Helmet>
        }
        <div className="sc_title sc_title_default mb-5 sticky-top position-sticky shop-title-holder">
            <div className="sc_item_title_wrap sc_align_center sc_position_below sc_subtitle_present"><h2
                className="sc_item_title sc_title_title sc_item_title_style_default"><span
                className="sc_item_title_line_left"></span><span className="sc_item_title_text">Mağaza</span><span
                className="sc_item_title_line_right"></span></h2></div>
        </div>
        <div className={"container sticky-top shop-filter-holder"}>

            <div className={"row"}>
                <div className={"col-12 col-md-6 m-auto"}>
                    <input onChange={(e) => onSearch(e.target.value)} type={"text"}
                           placeholder={"Arama yapın..."} className={"category-holder w-100"}/>
                </div>
            </div>

            <div className={"row"}>

                <div className={"col-12 col-md-8 d-flex justify-content-center m-auto flex-wrap"}>
                    {categories.map((c, index) => {
                        return <a key={index}
                                  className={"category-holder " + (activeCategory === c ? 'active' : '')}
                                  onClick={() => setActiveCategory(c)}>{c}</a>
                    })}
                    <a className={"category-holder " + (activeCategory === "" ? 'active' : '')}
                       onClick={() => setActiveCategory("")}>Tümünü Göster</a>
                </div>

                <div className={"row mt-2"}>
                    <div className={"w-auto m-auto"}>
                        {totalResults > 0 ?
                            <small>{totalResults} ürün listeleniyor</small>
                            :
                            <small>Aradığınız kriterlere uygun ürün bulunamadı</small>
                        }
                        {(search !== "" || activeCategory !== "") &&

                            <a className={"category-holder"} onClick={() => clearFilters()}><i
                                className={"fa fa-remove"}></i> Arama kriterlerini temizle</a>
                        }

                    </div>
                </div>
            </div>


        </div>
        {products.length > 0 &&
            <InfiniteScroll className={"container"}
                            dataLength={products.length}
                            next={() => getData()}
                            hasMore={hasMore}>
                <div className={"row mt-2 mt-md-5 p-2"}>
                    {products.map((p, index) => {
                        return <div className={"col-12 col-md-3 mb-5"} key={index}><ProductItem data={p}/></div>
                    })}
                </div>
            </InfiniteScroll>
        }
    </div>)
}

export default ProductList;