import './Management.css';
import Sidebar from "./Layout/Sidebar/Sidebar";
import {Route, Routes, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import UsersManagement from "./UsersManagement/UsersManagement";
import {Toaster} from "react-hot-toast";
import {useAuth} from "../Services/AuthContext";
import HomePageManagement from "./HomePageManagement/HomePageManagement";
import ContactManagement from "./ContactManagement/ContactManagement";
import Menu from "./ThemeManagement/Menu/Menu";
import PageManagement from "./PageManagement/PageManagement";
import ThemeManagement from "./ThemeManagement/ThemeManagement";
import ProductsManagement from "./ProductsManagement/ProductsManagement";
import EducationsManagement from "./EducationsManagement/EducationsManagement";
import PageEditor from "./Layout/Utils/PageEditor/PageEditor";
import BlogManagement from "./BlogManagement/BlogManagement";

const Management = () => {

    const navigate = useNavigate();
    const {loggedUser } = useAuth();
    useEffect(() => {
        if(loggedUser === null){
          navigate('/login')
        }
    }, [loggedUser]);

    return (
        <div className={"row mb-5 management-panel"}>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div className="container">
                    <div className="page-header__content">
                        <h2 className="page-header__title">Yönetim Paneli</h2>
                    </div>
                </div>
            </section>
            <section className="service-details pt-5">
                <div className="container">
                    <div className="row gutter-y-40">
                        <div className="col-md-3 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="00ms">
                            <Sidebar/>
                        </div>
                        <div className="col-md-9 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <Routes>
                                <Route path={'menu'} element={<Menu/>}/>
                                <Route path={'homepage/*'} element={<HomePageManagement/>}/>
                                <Route path={'theme/*'} element={<ThemeManagement/>}/>
                                <Route path={'users'} element={<UsersManagement/>}/>
                                <Route path={'products'} element={<ProductsManagement/>}/>
                                <Route path={'educations'} element={<EducationsManagement/>}/>
                                <Route path={'pages'} element={<PageManagement/>}/>
                                <Route path={'page-editor/*'} element={<PageEditor/>}/>
                                <Route path={'blog'} element={<BlogManagement/>}/>
                                <Route path={'blog-editor/*'} element={<PageEditor/>}/>
                                <Route path={'contact'} element={<ContactManagement/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </section>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </div>
    )
}

export default Management