import {formatDate} from "../../../Pages/Layout/Utils/DateUtils/DateUtils";

const MessageViewer = (props) => {

    return (<div className={"modal modal-lg"} id="staticBackdrop">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Mesajı Görüntüle</h5>
                    <button type="button" className="btn-close" onClick={() => props.onClose()}></button>
                </div>
                <div className="modal-body">
                    <div className="row">


                        <div className={"col-md-12"}>
                            <span className={"form-label text-left mx-2"}>Gönderen Ad Soyad :</span> <small className={"col-12 text-left"}>{props.data.name}</small>
                        </div>
                        <hr/>
                        <div className={"col-md-12"}>
                            <span className={"form-label text-left mx-2"}>Gönderen E-posta Adresi :</span> <small className={"col-12 text-left"}>{props.data.email}</small>
                        </div>
                        <hr/>
                        <div className={"col-md-12"}>
                            <span className={"form-label text-left mx-2"}>Tarih :</span> <small className={"col-12 text-left"}>{formatDate(props.data.createDate)}</small>
                        </div>
                        <hr/>
                        <div className={"col-md-12"}>
                            <span className={"form-label text-left mx-2"}>Mesaj </span> <hr/> <div className={"col-12 mx-2"}><small>{props.data.message}</small></div>
                        </div>

                        <div className={"col-12 text-right mt-4"}>
                            {props.data.status === 0 &&
                                <a onClick={() => props.onRead(props.data)} className="btn btn-outline-success mx-1"><i
                                    className={"fa fa-check mx-1"}></i> Okundu Olarak İşaretle</a>
                            }
                            <a onClick={() => props.onDelete(props.data)} className="btn btn-outline-danger mx-1"><i
                                className={"fa fa-save mx-1"}></i> Mesajı Sil</a>
                            <a onClick={() => props.onClose()} className="btn btn-secondary mx-1"><i
                                className={"fa fa-close mx-1"}></i> Kapat</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default MessageViewer