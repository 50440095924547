import React, {createContext, useState} from 'react';

export const LoaderContext = createContext();

export const LoaderProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const showLoader = (message = "") => {
        setMessage(message);
        setLoading(true);
    }

    const hideLoader = () => {
        setMessage("");
        setLoading(false);
    }

    return (
        <LoaderContext.Provider value={{loading, message, showLoader, hideLoader}}>
            {children}
        </LoaderContext.Provider>
    );
};