import React, {useEffect, useRef, useState} from "react";
import ManagementHomePageService from "../../../../Services/ManagementHomePageService";
import toast from "react-hot-toast";
import SliderItem from "../../../../Pages/HomePage/MainSlider/SliderItem/SliderItem";
import BaseService from "../../../../Services/BaseService";

const SliderEdit = (props) => {

    const divWidthRef = useRef();
    const [zoomRatio, setZoomRatio] = useState();
    const desktopContainerWidth = 1920;
    const [desktopItemCount, setDesktopItemCount] = useState(3);


    const [form, setForm] = useState({
        id: null,
        categoryTitle: '',
        title: '',
        background: '',
        creator: '',
        date: '',
        linkUrl: ''
    })
    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            var baseService = new BaseService();
            baseService.uploadImage(selectedFile).then(response => {
                setForm({...form, background: response.data});
            })
        }
    };

    const save = () => {
        ManagementHomePageService.saveSlider(form).then(response => {
            toast.success('Nesne kaydedildi');
            props.onClose()
        })
    }
    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / desktopContainerWidth);
        }
    }

    useEffect(() => {
        if (props.sliderId !== -1) {
            ManagementHomePageService.getSlider(props.sliderId).then(response => {
                setForm({...form, ...response.data});
                handleResize();
            });
        }

        ManagementHomePageService.getPageContent("Slider").then(response => {
            setDesktopItemCount(response.data.desktopItemCount);
            handleResize();
        })

    }, [props.sliderId]);


    useEffect(() => {
        handleResize();
    }, [divWidthRef])

    return (
        <div className="row">
            <div className={"row"} ref={divWidthRef}></div>


            <div className={"col-12 d-flex mt-2 mb-2"}>
                <label className={"form-label col-2 text-left my-auto"}>Önizleme</label>

            </div>
            <div className={"col-12 d-flex mt-2"}>
                <div className={"container"} style={{zoom: zoomRatio}}>
                    <div className={"row w-100"}>
                        <div className={"m-auto col-md-" + (12 / desktopItemCount)}>
                            <SliderItem data={form}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Nesne Başlığı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.title}
                           onChange={(e) => handleForm("title", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Kategori Başlığı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.categoryTitle}
                           onChange={(e) => handleForm("categoryTitle", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Oluşturan</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.creator}
                           onChange={(e) => handleForm("creator", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Tarih Alanı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.date}
                           onChange={(e) => handleForm("date", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Bağlantı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.linkUrl}
                           onChange={(e) => handleForm("linkUrl", e.target.value)}/>
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Fotoğraf</label>
                <div className={"col-9 m-auto"}>
                    <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={(e) => handleFileChange(e)}
                    />
                </div>
            </div>

            <div className={"col-md-12 text-right mt-4"}>
                <a onClick={() => props.onClose()} className="btn btn-secondary mx-2"><i
                    className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                <a onClick={() => save()} className="btn btn-secondary"><i className={"fa fa-save mx-1"}></i> Kaydet</a>
            </div>
        </div>
    )
}

export default SliderEdit