import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {AuthProvider} from "./Services/AuthContext";
import {ConfigProvider} from "./Services/ConfigContext";
import {LoaderProvider} from "./Services/LoaderContext";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <LoaderProvider>
        <ConfigProvider>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </ConfigProvider>
    </LoaderProvider>
);