import './HomePage.css'
import MainSlider from "./MainSlider/MainSlider";
import Features from "./Features/Features";
import React, {useEffect, useState} from "react";
import HomePageService from "../../Services/HomePageService";
import Promotes from "./Promotes/Promotes";
import Products from "./Products/Products";
import Educations from "./Educations/Educations";
import {Helmet} from "react-helmet-async";
import {useConfig} from "../../Services/ConfigContext";

function HomePage() {

    const [data, setData] = useState({
        sliders: {},
        features: [],
        promote: {},
        products: {},
        educations: {},
        layoutSort: [],
    })

    const elements = {
        slider: <MainSlider key={"slider"} data={data.sliders}></MainSlider>,
        features: <Features key={"feature"} data={data.features}></Features>,
        promote: <Promotes key={"promote"} data={data.promote}></Promotes>,
        products: <Products key={"product"} data={data.products}></Products>,
        educations: <Educations key={"education"} data={data.educations}></Educations>,
    }

    useEffect(() => {
        HomePageService.getHomePage().then(response => {
             let modifiedData = response.data;
             modifiedData.layoutSort = JSON.parse(modifiedData.layoutSort).sortList;
             setData(modifiedData);
         })
    }, []);


    const config = useConfig();
    const bannerData = config.banner; 

    return (<div className={"home-page-wrapper"}>
            {
                bannerData &&
                <Helmet>
                <title>{bannerData.metaTitle ?? ""} - Anasayfa</title>
                <meta name="description" content={bannerData?.metaDescription}/>
            </Helmet>
            }
             {data.layoutSort?.filter(d => d.enable).map((d) => {
                 return elements[d.id]
             })}
         </div>

    );
}

export default HomePage