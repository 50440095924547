import React, {createContext, useState, useEffect, useContext} from "react";
import { jwtDecode } from 'jwt-decode'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [loggedUser,setLoggedUser] = useState(false);
    const setToken = (givenToken ) => {
        if(givenToken === null){
            localStorage.removeItem("token");
            setLoggedUser(null);
        }else {
            const decodedJwt = jwtDecode(givenToken);
            setLoggedUser(decodedJwt);
            localStorage.setItem('token', givenToken);
        }
    }
    useEffect(() => {
        const storedToken = localStorage.getItem("token");
        setToken(storedToken ?? null);
    }, []);

    return (
        <AuthContext.Provider value={{ loggedUser, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
