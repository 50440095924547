import React, {useEffect, useState} from "react";
import ManagementHomePageService from "../../../../Services/ManagementHomePageService";
import toast from "react-hot-toast";
import FeatureItem from "../../../../Pages/HomePage/Features/FeatureItem/FeatureItem";
import BaseService from "../../../../Services/BaseService";

const FeatureEdit = (props) => {

    const [form, setForm] = useState({
        id: null,
        linkUrl: '',
        title: '',
        description: '',
        background: '',
    })

    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            var baseService = new BaseService();
            baseService.uploadImage(selectedFile).then(response => {
                setForm({...form, background: response.data});
            })
        }
    };

    const save = () => {
        ManagementHomePageService.saveFeature(form).then(response => {
            toast.success('Öne çıkarma kaydedildi');
            props.onClose()
        })
    }



    useEffect(() => {
        if(props.featureId !== -1){
            ManagementHomePageService.getFeature(props.featureId).then(response => {
                setForm({...form,...response.data});
            })
        }
    }, [props.featureId]);

    return (
        <div className="row">
            <div className={"col-12 d-flex mt-2 mb-2"}>
                <label className={"form-label col-2 text-left my-auto"}>Önizleme</label>

            </div>
            <div className={"col-12 d-flex mt-2"}>
                <div className={"m-auto"}>
                    <FeatureItem data={form}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Başlık</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.title} onChange={(e) => handleForm("title", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Açıklama</label>
                <div className="col-9 m-auto">
                    <input className="form-control" value={form.description} onChange={(e) => handleForm("description", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2 "}>
                <label className={"form-label col-3 text-left my-auto"}>Bağlantı</label>
                <div className="col-9 m-auto">
                    <input className="form-control" value={form.linkUrl} onChange={(e) => handleForm("linkUrl", e.target.value)}/>
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Fotoğraf</label>
                <div className={"col-9 m-auto"}>
                    <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={(e) => handleFileChange(e)}
                    />
                </div>
            </div>

            <div className={"col-md-12 text-right mt-4"}>
                <a onClick={() => props.onClose()} className="btn btn-secondary mx-2"><i className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                <a onClick={() => save()} className="btn btn-secondary"><i className={"fa fa-save mx-1"}></i> Kaydet</a>
            </div>
        </div>
    )
}

export default FeatureEdit