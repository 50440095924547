import BaseService from './BaseService';

class ManagementEducationsService extends BaseService {

    getEducations(search,page) {
        return this.apiClient.get('HomePageManagement/Educations?search='+search+'&page='+page);
    }
    saveEducation(education) {
        return this.apiClient.put('HomePageManagement/Education',education);
    }
    getEducation(id){
        return this.apiClient.get('HomePageManagement/Education/'+id);
    }
    deleteEducation(id){
        return this.apiClient.delete('/HomePageManagement/Education/'+id);
    }
}

export default new ManagementEducationsService();