import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import "./EditorFaq.css"
import TextEditor from "../../TextEditor/TextEditor";
import ElementFaq from "../../../../../Pages/PageElements/ElementFaq/ElementFaq";

const EditorFaq = (props) => {

    const [zoomRatio, setZoomRatio] = useState(false);
    const divWidthRef = useRef();
    const containerWidth = 1200;

    const [editingFaqIndex, setEditingFaqIndex] = useState(null);
    const [editingFaqTitle, setEditingFaqTitle] = useState("");
    const [editingFaqContent, setEditingFaqContent] = useState("");


    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / containerWidth);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);


    const [form, setForm] = useState({
        faqs: [],
        layoutCols: 6
    });

    const layoutColsOptions = [
        {value: 6, label: '50%'},
        {value: 12, label: '100%'},
    ]


    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }

    useEffect(() => {
        if (props.hasOwnProperty("data")) {
            setForm({...form, ...props.data});
        }
    }, [props]);

    const editFaq = (index) => {
        setEditingFaqTitle(form.faqs[index].title);
        setEditingFaqContent(form.faqs[index].content);
        setEditingFaqIndex(index);
    }

    const addFaq = () => {
        setEditingFaqIndex(-1);
        setEditingFaqTitle('');
        setEditingFaqContent('');
    }

    const deleteFaq = (index) => {
        setForm(prevForm => {
            const updatedFaqs = prevForm.faqs.filter((_, i) => i !== index);
            return {...prevForm, faqs: updatedFaqs};
        });
        setEditingFaqIndex(null);
    };

    const saveFaq = () => {
        setForm(prevForm => {
            const updatedFaqs = editingFaqIndex > -1
                ? prevForm.faqs.map((faq, i) => i === editingFaqIndex ? {
                    title: editingFaqTitle,
                    content: editingFaqContent
                } : faq)
                : [...prevForm.faqs, {title: editingFaqTitle, content: editingFaqContent}];

            return {...prevForm, faqs: updatedFaqs};
        });
        setEditingFaqIndex(null);
    };
    return (
        <div>
            <div className={"row mb-30"}>
                <div className={"col-md-12"} ref={divWidthRef}>
                    <label className={"form-label"}>Önizleme</label>
                </div>
                <div className={"col-md-12"} style={{zoom: zoomRatio}}>
                    <ElementFaq data={form}></ElementFaq>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>SSS Düzeni (Masaüstü)</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={layoutColsOptions.find(ipo => ipo.value === form.layoutCols)}
                                onChange={(selectedOption) => handleForm("layoutCols", selectedOption.value)}
                                options={layoutColsOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
            </div>

            <div className={"text-right mt-4"}>
                <a className="btn btn-secondary" onClick={() => addFaq()}><i
                    className={"fa fa-plus mx-1"}></i> Yeni Ekle</a>
            </div>
            <table className={"table management__table mt-4"}>
                <thead>
                <tr>
                    <th>SSS Başlığı</th>
                    <th>Operasyonlar</th>
                </tr>
                </thead>
                <tbody>
                {form.faqs.map((faq, index) => {
                        return (
                            <tr key={index}>
                                <td>{faq.title}</td>
                                <td className="text-right">
                                    <a onClick={() => editFaq(index)}
                                       className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                    <a onClick={() => {
                                        deleteFaq(index)
                                    }} className="btn btn-outline-danger btn-sm mx-1 my-1">Sil</a>
                                </td>
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>


            <div className={"modal-footer"}>
                <div className={"col-md-12 text-right"}>
                    <a className="btn btn-secondary mx-2" onClick={() => props.closeElementEditor()}><i
                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                    <a className="btn btn-secondary" onClick={() => props.saveElement(form)}><i
                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                </div>
            </div>


            {
                editingFaqIndex !== null &&
                <div className={"modal"} id="staticBackdrop">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">SSS {editingFaqIndex === -1 ? 'Ekle' : 'Düzenle'}</h5>
                                <button type="button" className="btn-close"
                                        onClick={() => setEditingFaqIndex(null)}></button>
                            </div>
                            <div className="modal-body">

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>SSS Başlığı</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <input className="form-control" value={editingFaqTitle}
                                           onChange={(e) => setEditingFaqTitle(e.target.value)}/>
                                </div>

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>SSS İçeriği</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <TextEditor height={200} onEditorChange={(e) => setEditingFaqContent(e)}
                                                initialValue={editingFaqContent}></TextEditor>
                                </div>

                                <div className={"col-md-12 text-right"}>
                                    <a className="btn btn-secondary mx-2" onClick={() => setEditingFaqIndex(null)}><i
                                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                                    <a className="btn btn-secondary" onClick={() => saveFaq()}><i
                                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditorFaq;