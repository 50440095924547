import './Footer.css'
import {v4 as uuidv4} from "uuid";
import React from 'react';
import {useConfig} from "../../../Services/ConfigContext";

const Footer = () => {

    const config = useConfig();
    const footer = config.footer;
    const getFooterItem = (f) => {
        return (<a className={"col-12 col-md-auto"} key={uuidv4()} href={f.url}>{f.name}</a>)
    }

    return (
        <>
            <hr className={"mt-0"}/>
            <footer className="container pb-3">
                <div className={"row justify-content-between footer-items"}>
                    <div className={"w-auto"}>Serap Koçak {new Date().getFullYear()}, Tüm Hakları Saklıdır</div>
                    <div className={"w-auto d-flex gap-4"}>
                        <div className={"row"}>
                            {footer.map(f => {
                                return getFooterItem(f)
                            })}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer