import React, {useState} from "react";
import Slider from "./Slider/Slider"
import Feature from "./Feature/Feature";
import Promote from "./Promote/Promote";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import LayoutManagement from "./LayoutManagement/LayoutManagement";
import Products from "./Products/Products";
import Educations from "./Educations/Educations";

const HomePageManagement = () => {

    const [activeTab, _setActiveTab] = useState(useParams()["*"]);
    const navigate = useNavigate();

    const setActiveTab = (tabName) => {
        _setActiveTab(tabName);
        navigate("/management/homepage/" + tabName);
    }


    return (
        <div className={"row"}>
            <ul className="acn-theme-breadcrumb list-unstyled">
                <li><a href="/management">Yönetim Paneli</a></li>
                <li><span>Anasayfa Yönetimi</span></li>
            </ul>
            <div className={"col-12"}>
                <ul className="nav nav-tabs mb-3 homepage-tabs" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("layout")}
                           className={"nav-link " + (activeTab === 'layout' ? 'active' : '')}>Düzen</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("slider")}
                           className={"nav-link " + (activeTab === 'slider' ? 'active' : '')}>Slider</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("feature")}
                           className={"nav-link " + (activeTab === 'feature' ? 'active' : '')}>Öne Çıkanlar</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("promote")}
                           className={"nav-link " + (activeTab === 'promote' ? 'active' : '')}>Tanıtım</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("product")}
                           className={"nav-link " + (activeTab === 'product' ? 'active' : '')}>Ürünler</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("education")}
                           className={"nav-link " + (activeTab === 'education' ? 'active' : '')}>Eğitimler</a>
                    </li>
                </ul>
                <div className="tab-content" id="ex1-content">
                    <div className={"tab-pane fade menu show active"}>
                        <Routes>
                            <Route path={'layout'} element={<LayoutManagement/>}></Route>
                            <Route path={'slider'} element={<Slider/>}></Route>
                            <Route path={'feature'} element={<Feature/>}></Route>
                            <Route path={'promote'} element={<Promote/>}></Route>
                            <Route path={'product'} element={<Products/>}></Route>
                            <Route path={'education'} element={<Educations/>}></Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePageManagement