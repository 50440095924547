import React, {useEffect, useRef, useState} from "react";
import ManagementHomePageService from "../../../../Services/ManagementHomePageService";
import toast from "react-hot-toast";
import BaseService from "../../../../Services/BaseService";
import Select from "react-select";
import PromoteLeftSectionItem from "../../../../Pages/HomePage/Promotes/PromoteLeftSectionItem/PromoteLeftSectionItem";
import PromoteRightSectionItem
    from "../../../../Pages/HomePage/Promotes/PromoteRightSectionItem/PromoteRightSectionItem";
import PromoteBottomSectionItem
    from "../../../../Pages/HomePage/Promotes/PromoteBottomSectionItem/PromoteBottomSectionItem";
import OwlCarousel from "react-owl-carousel";

const PromoteEdit = (props) => {

    const divWidthRef = useRef();
    const [zoomRatio, setZoomRatio] = useState();
    const desktopContainerWidth = 1920;


    const [form, setForm] = useState({
        id: null,
        categoryTitle: '',
        title: '',
        background: '',
        creator: '',
        date: '',
        description: '',
        position: 'left',

    })


    const promotePositionOptions = [
        {value: 'left', label: 'Sol'},
        {value: 'right', label: 'Sağ Üst'},
        {value: 'bottom', label: 'Sağ Alt'},
    ]
    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            var baseService = new BaseService();
            baseService.uploadImage(selectedFile).then(response => {
                setForm({...form, background: response.data});
            })
        }
    };

    const save = () => {
        ManagementHomePageService.savePromote(form).then(response => {
            toast.success('Nesne kaydedildi');
            props.onClose()
        })
    }
    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / desktopContainerWidth);
        }
    }

    useEffect(() => {
        if (props.promoteId !== -1) {
            ManagementHomePageService.getPromote(props.promoteId).then(response => {
                setForm({...form, ...response.data});
                handleResize();
            });
            ManagementHomePageService.getPageContent("Promote").then(response => {
                handleResize();
            })
        }
    }, [props.promoteId]);


    useEffect(() => {
        handleResize();
    }, [divWidthRef])

    return (
        <div className="row">
            <div className={"row"} ref={divWidthRef}></div>
            <div className={"col-12 d-flex mt-2 mb-2"}>
                <label className={"form-label col-2 text-left my-auto"}>Önizleme</label>
            </div>

            <div className={"col-12 d-flex mt-2"}>
                <div className={"container"} style={{zoom: zoomRatio}}>
                    <div className={"row w-100"}>
                        {form.position === "left" &&
                            <div className={"col-md-8"}>
                                <PromoteLeftSectionItem data={form}/>
                            </div>
                        }
                        {form.position === "right" &&
                            <div
                                className="sc_blogger sc_blogger_default sc_blogger_default_over_bottom_center sc_item_filters_tabs_none alignnone column_gap_no pb-0 col-md-4">
                                <div
                                    className="sc_blogger_slider sc_item_slider slider_swiper_outer slider_outer slider_outer_nocontrols slider_outer_pagination slider_outer_pagination_bullets slider_outer_pagination_pos_bottom slider_outer_nocentered slider_outer_overflow_hidden slider_outer_one">
                                    <OwlCarousel
                                        className="main-slider-two__carousel acn-theme-owl__carousel acn-theme-owl__carousel--basic-nav owl-carousel owl-theme"
                                        loop={true}
                                        nav={false}
                                        dots={true}
                                        autoplay={true}
                                        margin={10}
                                        items={1}
                                        autoplayTimeout={3000}>
                                        <PromoteRightSectionItem data={form}/>
                                    </OwlCarousel>
                                </div>
                            </div>
                        }
                        {form.position === "bottom" &&
                            <div className={"col-md-4"}>
                                <div
                                    className="sc_blogger sc_blogger_list sc_blogger_list_with_rounded_image sc_item_filters_tabs_none alignnone column_gap_no pt-0">
                                    <div
                                        className="sc_blogger_content sc_item_content sc_item_posts_container">
                                        <PromoteBottomSectionItem data={form}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Tanıtım Bölümü</label>
                <div className={"col-9 m-auto"}>
                    <Select isSearchable={false}
                            isMulti={false}
                            value={promotePositionOptions.find(ipo => ipo.value === form.position)}
                            onChange={(selectedOption) => handleForm("position", selectedOption.value)}
                            options={promotePositionOptions}
                            classNamePrefix="form-control-select"/>
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Nesne Başlığı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.title}
                           onChange={(e) => handleForm("title", e.target.value)}/>
                </div>
            </div>



            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Nesne Kısa Açıklaması</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.description}
                           onChange={(e) => handleForm("description", e.target.value)}/>
                </div>
            </div>


            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Kategori Başlığı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.categoryTitle}
                           onChange={(e) => handleForm("categoryTitle", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Oluşturan</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.creator}
                           onChange={(e) => handleForm("creator", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Tarih Alanı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.date}
                           onChange={(e) => handleForm("date", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Bağlantı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.linkUrl}
                           onChange={(e) => handleForm("linkUrl", e.target.value)}/>
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Fotoğraf</label>
                <div className={"col-9 m-auto"}>
                    <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={(e) => handleFileChange(e)}
                    />
                </div>
            </div>

            <div className={"col-md-12 text-right mt-4"}>
                <a onClick={() => props.onClose()} className="btn btn-secondary mx-2"><i
                    className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                <a onClick={() => save()} className="btn btn-secondary"><i className={"fa fa-save mx-1"}></i> Kaydet</a>
            </div>
        </div>
    )
}

export default PromoteEdit