import './Search.css'
import {useState} from "react";

const Search = (props) => {

    const [searchText,setSearchText] = useState("");

    const onSearch = () => {
        props.onSearch(searchText);
    }

    const handleInputChange = (value) => {
        if(value === ""){
            props.onSearch("");
        }
        setSearchText(value);
    }

    return (
        <div className="col-md-12">
            <div className="search-popup__form">
                <input onKeyPress={(e) => e.key === 'Enter' ? onSearch() : ''} type="text" id="search" onChange={(e) => handleInputChange(e.target.value)} placeholder="Arama yap..."/>
                <button onClick={() => onSearch()}  className="acn-theme-btn acn-theme-btn--base">
                    <i className="icon-search"></i>
                </button>
            </div>
        </div>
    )
}

export default Search