import "./TextEditor.css"
import {Editor} from "@tinymce/tinymce-react";
import {useRef} from "react";

const TextEditor = (props) => {

    const editorRef = useRef(null);


    return (
        <Editor
            tinymceScriptSrc='/tinymce/tinymce.min.js'
            onEditorChange={props.onEditorChange}
            value={props.initialValue}
            licenseKey='gpl'
            onInit={(_evt, editor) => editorRef.current = editor}
            init={{
                height: props.height || 500,
                menubar: true,
                plugins: 'table',
                line_height_formats: '0.8 0.9 1 1.2 1.4 1.6 1.8',
                toolbar: 'undo redo | styleselect | fontfamily fontsize lineheight | ' +
                    'bold italic underline forecolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist | table',
                content_style:
                    "@import url('https://fonts.googleapis.com/css2?family=Karla&family=Roboto&family=Lora&display=swap'); body { font-family: 'Lora', sans-serif; font-size : 10pt; } p { margin : 0 !important; } br { margin: 0 !important; display: block; }",
                font_family_formats:
                    "Arial Black=arial black,avant garde;Karla=Karla;Lora=lora;",
                font_size_formats:
                    "6pt 8pt 10pt 12pt 16pt 17pt 18pt 24pt 30pt 36pt 48pt 54pt",
                setup: function (editor) {
                    editor.on('keydown', function (event) {
                        if (event.key === 'Enter' && !event.ctrlKey) {
                            event.preventDefault();
                            editor.execCommand('InsertParagraph');
                        }
                    });
                    editor.on('keydown', function (event) {
                        if (event.key === 'Enter' && event.ctrlKey) {
                            event.preventDefault();
                            editor.execCommand('InsertLineBreak');
                        }
                    });
                },
            }}
        />
    )
}

export default TextEditor
