import "./PageManagement.css"
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import swal from "sweetalert2";
 import toast from "react-hot-toast";
import ManagementPageDetailService from "../../Services/ManagementPageDetailService";
import Search from "../Layout/Search/Search";
import Paginator from "../Layout/Paginator/Paginator";
const PageManagement = () => {

    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [pageValue, setPageValue] = useState(1);
    const [data, setData] = useState({
        total: 0,
        result: []
    });

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const onPageChange = (value) => {
        setPageValue(value);
    }
    const deleteDialog = (page) => {
        swal.fire({
            title: "İşleme devam et",
            html: "<b>"+page.title + "</b> başlıklı sayfa silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                ManagementPageDetailService.deletePage(page.id).then(response => {
                    toast.success('Sayfa silindi');
                    getData();
                }).catch(e => {
                    toast.error(e.response.data);
                })
            }
        })
    }

    const getData = () => {
        ManagementPageDetailService.getPageDetails(searchValue, pageValue).then(response => {
            setData(response.data);
        });
    }

    useEffect(() => {
        getData();
    }, [searchValue, pageValue]);

    return (
        <div className="row">
            <ul className="acn-theme-breadcrumb list-unstyled">
                <li><a href="/management">Yönetim Paneli</a></li>
                <li><span>Sayfa Yönetimi</span></li>
            </ul>

            <div className="col-12 d-flex">
                <div className="col-10 pr-20">
                    <Search onSearch={onSearch}></Search>
                </div>
                <div className="col-2 d-flex">
                    <a className="btn text-center px-3 py-2 mx-auto mr-0" onClick={() => navigate("../page-editor/new")}>Sayfa Ekle</a>
                </div>
            </div>
            <div className="col-12 mt-4 mb-10">
                <table className="table management__table">
                    <thead>
                    <tr>
                        <th>Sayfa Başlığı</th>
                        <th>URL</th>
                        <th>Operasyonlar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.result.map(d => {
                            return (
                                <tr key={d.id}>
                                    <td>
                                        {d.title}
                                    </td>
                                    <td>
                                        {d.url}
                                    </td>
                                    <td className="text-right">
                                        <a onClick={() => navigate('../page-editor/'+d.id)}
                                           className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                        <a onClick={() => {
                                            deleteDialog(d)
                                        }} className="btn btn-outline-danger btn-sm mx-1 my-1">Sayfayı Sil</a>
                                    </td>
                                </tr>
                            )
                        })
                    }

                    </tbody>
                </table>
            </div>
            <Paginator totalResult={data.total} currentPage={pageValue} onPageChange={onPageChange}></Paginator>
        </div>
    )
}

export default PageManagement