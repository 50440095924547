import React, {useEffect, useRef, useState} from "react";
import BaseService from "../../../Services/BaseService";
import Header from "../../../Pages/Layout/Header/Header";
import Topbar from "../../../Pages/Layout/Topbar/Topbar";
import HomePageService from "../../../Services/HomePageService";
import ManagementHomePageService from "../../../Services/ManagementHomePageService";
import toast from "react-hot-toast";

const Banner = () => {

    const divWidthRef = useRef();
    const [zoomRatio, setZoomRatio] = useState();
    const desktopContainerWidth = 1920;

    const [menu,setMenu] = useState([]);


    const [form, setForm] = useState({
        logo: null,
        logoTitle: '',
        background: '',
        backgroundButtonText: '',
        backgroundButtonUrl: '',
        instagram : '',
        youtube : '',
        pinterest : '',
        shopify : '',
    });

    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / desktopContainerWidth);
        }
    }

    useEffect(() => {
        handleResize();
    }, [divWidthRef])

    useEffect(() => {
        handleResize();
        getData();
    }, []);

    const getData = () => {
        HomePageService.getThemeConfig().then(response => {
            setMenu(response.data.menu);
            var bannerData = JSON.parse(response.data.banner);
            setForm({...form,...bannerData});
        })
    }

    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }

    const handleFileChange = (key,event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            var baseService = new BaseService();
            baseService.uploadImage(selectedFile).then(response => {
                setForm({...form, [key]: response.data});
            })
        }
    };

    const save = () => {
        ManagementHomePageService.savePageContent("Banner",JSON.stringify(form)).then(response => {
            getData();
            toast.success('İçerik kaydedildi');
        })

    }




    return (
        <div className="row" ref={divWidthRef}>
            <div className={"col-12 d-flex mt-2 mb-2"}>
                <label className={"form-label col-2 text-left my-auto"}>Önizleme</label>
            </div>
            <div className={"col-12 d-flex mt-2"}>
                <div style={{zoom : zoomRatio}} className={"w-100"}>
                    <Topbar data={form}/>
                    <Header menu={menu}/>
                </div>
            </div>


            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Logo</label>
                <div className={"col-9 m-auto"}>
                    <input
                        className="form-control"
                        type="file"
                        onChange={(e) => handleFileChange("logo",e)}
                    />
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Logo Yazısı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.logoTitle} onChange={(e) => handleForm("logoTitle", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Instagram Adresi</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.instagram} onChange={(e) => handleForm("instagram", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>YouTube Adresi</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.youtube} onChange={(e) => handleForm("youtube", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Pinterest Adresi</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.pinterest} onChange={(e) => handleForm("pinterest", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Shopify Adresi</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.shopify} onChange={(e) => handleForm("shopify", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Arkaplan Fotoğrafı</label>
                <div className={"col-9 m-auto"}>
                    <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={(e) => handleFileChange("background",e)}
                    />
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Arkaplan Bağlantı Başlığı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.backgroundButtonText} onChange={(e) => handleForm("backgroundButtonText", e.target.value)}/>
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Arkaplan Bağlantı Adresi</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.backgroundButtonUrl} onChange={(e) => handleForm("backgroundButtonUrl", e.target.value)}/>
                </div>
            </div>


            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>SEO Meta Title</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.metaTitle} onChange={(e) => handleForm("metaTitle", e.target.value)}/>
                </div>
            </div>


            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>SEO Meta Description</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.metaDescription} onChange={(e) => handleForm("metaDescription", e.target.value)}/>
                </div>
            </div>

            <div className={"col-md-12 text-right mt-4"}>
                <a onClick={() => save()} className="btn btn-secondary"><i className={"fa fa-save mx-1"}></i> Kaydet</a>
            </div>
        </div>
    )
}

export default Banner;