import './Topbar.css';
import {useConfig} from "../../../Services/ConfigContext";
import 'swiper/css';
import React from 'react';

function Topbar() {
    const config = useConfig();
    const data = config.banner;
    return (
        data !== null &&
        <div className="container">
            <div className={"row"}>
                <div className="col-12 col-md-3  topbar-holder">
                    <div className="sc_layouts_item skocak-element my-auto">
                        <div className="skocak-widget-container text-center my-4">
                            <a href="/"
                               className="sc_layouts_logo sc_layouts_logo_default">
                                <img className="lazyload_inited logo_image" src={config.imagesPath + data.logo}
                                     alt="Serap Koçak"/>
                                <br/>
                                {data.logoTitle}
                            </a>
                        </div>
                        <div className="pk-social-links-items d-flex px-4 justify-content-evenly mt-3 header-social-container d-none d-md-flex">
                            {data.instagram.length > 0 &&
                            <div className="pk-social-links-item pk-social-links-youtube">
                                <a href={data.instagram}
                                   className="pk-social-links-link d-flex">
                                    <i className="fab fa-instagram d-flex justify-content-center"></i>
                                </a>
                            </div>
                            }

                            {data.youtube.length > 0 &&
                            <div className="pk-social-links-item pk-social-links-youtube">
                                <a href={data.youtube}
                                   className="pk-social-links-link d-flex">
                                    <i className="fab fa-youtube d-flex justify-content-center"></i>
                                </a>
                            </div>
                            }

                            {data.pinterest.length > 0 &&
                            <div className="pk-social-links-item pk-social-links-youtube">
                                <a href={data.pinterest}
                                   className="pk-social-links-link d-flex">
                                    <i className="fab fa-pinterest d-flex justify-content-center"></i>
                                </a>
                            </div>
                            }

                            {data.shopify.length > 0 &&
                            <div className="pk-social-links-item pk-social-links-youtube">
                                <a href={data.shopify}
                                   className="pk-social-links-link d-flex">
                                    <i className="fab fa-shopify d-flex justify-content-center"></i>
                                </a>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-9 position-relative banner-item-container">

                    <img src={config.imagesPath + data.background} alt="Serap Koçak"
                         className={"banner-image"}/>
                    <div className="banner-image-overlay"></div>
                    <div className={"position-absolute w-100 h-100 d-flex justify-content-end p-5 top-0 banner-item-link-container"}>
                        {data.backgroundButtonText.length > 0 &&
                        <a href={data.backgroundButtonUrl}
                           className="sc_button sc_button_default sc_button_size_small my-auto"><span
                            className="sc_button_title">{data.backgroundButtonText}</span></a>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar