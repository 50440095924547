import {useEffect, useState} from "react";
import "./Paginator.css"

const Paginator = (props) => {

    const [totalPage,setTotalPage] = useState(1);

    useEffect(() => {
        if(props.totalResult > 0){
            setTotalPage(Math.ceil(props.totalResult/ ( props.take ? props.take : 10)));
        }
    }, [props.totalResult]);

    return (
        <div className="col-md-12 d-flex">
            <ul className="post-pagination m-auto mr-0 ">
                {props.currentPage > 1 &&
                    <li>
                        <a onClick={() => {props.onPageChange(props.currentPage-1)}}><span className="post-pagination__icon"><i
                            className="fas fa-chevron-left"></i></span></a>
                    </li>
                }

                {
                    Array(totalPage).fill(null).map((_, i) => {
                        return (
                            <li key={i} className={ props.currentPage === i+1 ? 'active' : ''} >
                                <a onClick={() => props.onPageChange(i+1)}>{i+1}</a>
                            </li>
                        )
                    })
                }

                { (props.currentPage < totalPage) &&
                    <li>
                        <a onClick={() => {props.onPageChange(props.currentPage+1)}}><span className="post-pagination__icon"><i
                            className="fas fa-chevron-right"></i></span></a>
                    </li>
                }
            </ul>
        </div>
    )
}
export default Paginator