import React, { useContext } from 'react';
import "./Loader.css"
import {LoaderContext} from "../../../../Services/LoaderContext";
import {useConfig} from "../../../../Services/ConfigContext";

const Loader = () => {
    const { loading, message } = useContext(LoaderContext);
    const config = useConfig();

    if (!loading) return null;

    return (
        <div className="loader-overlay">
            <div className="loader">
                <img src={config.imagesPath+"favicon.png"}/>
            </div>

            <div className={"mt-4 text-center"}>

                <span className={"loading-text"}>Lütfen bekleyiniz...</span>
                <br/>
                {message}
            </div>
        </div>
    );
};

export default Loader;