import React, {useEffect, useRef, useState} from "react";
import swal from "sweetalert2";
import toast from "react-hot-toast";
import ManagementHomePageService from "../../../Services/ManagementHomePageService";
import {ReactSortable} from "react-sortablejs";
import HomePage from "../../../Pages/HomePage/HomePage";


const LayoutManagement = () => {

    const [layoutSorting, setLayoutSorting] = useState(false);

    const [data, setData] = useState([
        {id: 'slider', order: 0, enable: true},
        {id: 'features', order: 0, enable: true},
        {id: 'promote', order: 0, enable: true},
        {id: 'products', order: 0, enable: true},
        {id: 'educations', order: 0, enable: true}
    ]);

    const translations = {
        'slider' : 'Slider',
        'features' : 'Uygulama Adımları',
        'promote' : 'Tanıtım',
        'products' : 'Ürünler',
        'educations' : 'Eğitimler',
    }


    const saveLayoutSorting = () => {
        let sortCount = 0;
        let newSortList = data.map((d) => {
            sortCount++;
            return {
                id: d.id,
                order: sortCount,
                enable : d.enable
            }
        })
        ManagementHomePageService.savePageContent("LayoutSort",JSON.stringify({sortList : newSortList})).then(response => {
            toast.success("Sıralama kaydedildi");
            setLayoutSorting(false);
        });
    }

    const getData = () => {
        setData([]);
        ManagementHomePageService.getPageContent("LayoutSort").then((response) => {
            setData(response.data.sortList);
        })
    }

    const changeStatus = (id,status) => {
        let currentData = data;
        currentData.find(d => d.id === id).enable = status;
        setData([]);
        setTimeout(() => {
            setData(currentData);
        })
    }

    useEffect(() => {
        if (!layoutSorting) {
            getData();
        }
    }, [layoutSorting]);

    useEffect(() => {
        getData();
    }, []);



    return (
        <div className="col-12 mb-10">
            <div className={"col-12 mt-2"}>
                <div className={"text-right p-0 mb-2"}>
                    {!layoutSorting &&
                        <a onClick={() => setLayoutSorting(true)} className="btn btn-secondary mx-2"><i
                            className={"fa fa-sort mx-1"}></i> Sıralamayı Etkinleştir</a>}
                    {layoutSorting &&
                        <a onClick={() => { setLayoutSorting(false); getData() }} className="btn btn-secondary mx-2"><i
                            className={"fa fa-cancel mx-1"}></i> Sıralamayı İptal Et</a>}
                    {layoutSorting && <a onClick={() => saveLayoutSorting()} className="btn btn-secondary mx-2"><i
                        className={"fa fa-check mx-1"}></i> Sıralamayı Kaydet</a>}
                </div>
            </div>
            <table className="table management__table contact_table">
                <thead>
                <tr>
                    <th>Bölüm</th>
                    <th>Operasyonlar</th>
                </tr>
                </thead>
                <ReactSortable list={data} setList={setData} disabled={!layoutSorting} tag={"tbody"}>
                    {data.length > 0 &&
                        data.map(d => {
                            return (
                                <tr key={d.id} draggable={true}>
                                    <td>
                                        { translations[d.id]}
                                    </td>
                                    <td className={"text-right"}>
                                        {!d.enable &&
                                        <a className={"btn btn-outline-success "+(!layoutSorting ? 'disabledElement' : '')} onClick={() => changeStatus(d.id,true)}>Aktifleştir</a>
                                        }
                                        {d.enable &&
                                        <a className={"btn btn-outline-danger "+(!layoutSorting ? 'disabledElement' : '')} onClick={() => changeStatus(d.id,false)}>Deaktif Et</a>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </ReactSortable>
            </table>

        </div>
    )
}

export default LayoutManagement