import BaseService from './BaseService';

class ManagementThemeService extends BaseService {

    getMenu() {
        return this.apiClient.get('HomePageManagement/Menu');
    }

    setMenu(menuContent) {
        return this.apiClient.put('HomePageManagement/Menu', menuContent);
    }

    getFooter() {
        return this.apiClient.get('HomePageManagement/Footer');
    }

    setFooter(footerContent) {
        return this.apiClient.put('HomePageManagement/Footer', footerContent);
    }

}

export default new ManagementThemeService();