import './Products.css';
import ProductItem from "./ProductItem/ProductItem";
import OwlCarousel from "react-owl-carousel";
import React from "react";
import {useConfig} from "../../../Services/ConfigContext";

const Products = ({data}) => {

    const pageContent = JSON.parse(data.pageContent);
    const config = useConfig();


    return (
        <div className="page-wrapper alter_bg py-5">
            <div
                className="elementor-element elementor-element-f6f5dee sc_fly_static elementor-widget elementor-widget-trx_sc_title">
                <div className="elementor-widget-container">
                    <div className="sc_title sc_title_default">
                        <div className="sc_item_title_wrap sc_align_center sc_position_below sc_subtitle_present">
                            <h2
                                className="sc_item_title sc_title_title sc_item_title_style_default"><span
                                className="sc_item_title_line_left"></span><span
                                className="sc_item_title_text">{pageContent.header}</span><span
                                className="sc_item_title_line_right"></span></h2></div>
                        <span
                            className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_below sc_item_title_style_default">{pageContent.title}</span>
                    </div>
                </div>
            </div>
            <div className={"container"}>

                <div className={"row d-flex mt-5 "}>
                    <div className="col-md-9 mb-5">
                        <OwlCarousel
                            className="owl-carousel owl-theme"
                            loop={true}
                            nav={false}
                            dots={false}
                            autoplay={data.products.length > 3}
                            autoplayHoverPause={true}
                            autoplayTimeout={5000}
                            smartSpeed={2000}
                            responsive={{
                                0: {
                                    "items": 1,
                                    "nav": false,
                                    "dots": true,
                                    "margin": 10
                                },
                                768: {
                                    "items": 3,
                                    "nav": false,
                                    "dots": false,
                                    "margin": 20
                                }
                            }}>
                            {data.products.map((p, index) => {
                                return <div className={"w-100"} key={index}><ProductItem data={p}/></div>
                            })}
                        </OwlCarousel>

                    </div>
                    <div className={"col-md-3 position-relative"}>
                        <div
                            className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_even sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_image_position_top sc_blogger_title_tag_h5 post-188 product type-product status-publish has-post-thumbnail product_cat-accessories product_tag-item product_tag-store product_tag-trend first instock taxable shipping-taxable purchasable product-type-variable">
                            <div className="sc_blogger_item_body">
                                <div
                                    className="post_featured with_thumb hover_shop sc_item_featured sc_blogger_item_featured post_featured_bg mb-0"
                                    data-ratio="1:1">
                                    <div className="featured_bg_wrapper">
                                        <div className="featured_bg" style={{backgroundImage : 'url('+config.imagesPath + pageContent.background+')'}}>
                                            <div className="position-absolute col-12 h-100 d-flex black-opacity-bg">
                                                <div className={"m-auto justify-content-center text-center"}>
                                                    <h4 className="sc_blogger_item_title entry-title text-center p-4"><a className={"color-white"}
                                                                                                                         href="/magaza">
                                                        {pageContent.shopTitle}
                                                    </a>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="sc_blogger_item_content entry-content mt-0 d-flex">
                                    <a href="/magaza" className="sc_button sc_button_default sc_button_size_small m-auto mt-4"><span className="sc_button_title">{pageContent.shopButtonTitle}</span></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products
