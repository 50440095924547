import React from "react";
import {useConfig} from "../../../../Services/ConfigContext";

const PromoteLeftSectionItem = ({data}) => {

    const config = useConfig();

    return (<div className="sc_blogger_item sc_blogger_item_wide sc_blogger_item_with_image mb-4 pt-4 border-top-suggestion">
        <div className="d-flex col-12 gap-3">
            <div
                className="post_featured with_thumb sc_item_featured post_featured_bg col-4 my-auto "
                data-ratio="1:1">
                <div className="featured_bg_wrapper">
                    <div className="featured_bg"
                         style={{backgroundImage: 'url(' + config.imagesPath + data.background + ')'}}></div>
                </div>
            </div>
            <div
                className="col-8 my-auto">
                <div
                    className="post_meta  post_meta_categories">
                                                                                        <span
                                                                                            className="post_meta_item post_categories"><a href={data.linkUrl}>{data.categoryTitle}</a></span>
                </div>
                <h4 className="sc_blogger_item_title entry-title"><a
                    href={data.linkUrl}>{data.title}</a></h4>
                <div
                    className="post_meta sc_blogger_item_meta post_meta">
                    <a className="post_meta_item post_author"  href={data.linkUrl}><span
                        className="post_author_by">By</span><span
                        className="post_author_name">{data.creator}</span></a><span
                    className="post_meta_item post_date"><a href={data.linkUrl}>{data.date}</a></span>
                </div>
                <div
                    className="sc_blogger_item_excerpt">{data.description}</div>
            </div>
        </div>
    </div>
    );
}

export default PromoteLeftSectionItem