import {useEffect, useState} from "react";
import "./ElementSidebarContent.css"
import React from 'react';

const ElementSidebarContent = (props) => {

    const [data, setData] = useState({
        contentPosition: 'right', //left, right
        sidebarColsDesktop: 6, // 2-8
        sidebarColsMobile: 12, // 6-12
        sidebarItems: [],
        sidebarTitle: "",
        content: '',
    });

    useEffect(() => {
        if (props) {
            setData({...data, ...props.data});
        }
    }, [props]);

    return (
        <div
            className={"mt-20 w-100 d-flex " + (data.contentPosition === 'right' ? 'flex-direction-left' : 'flex-direction-right')}>
            <div className={"col-md-" + data.sidebarColsDesktop + " col-xs-" + data.sidebarColsMobile}>
                <div className="sidebar">
                    <aside className="widget-area">
                        <div className="sidebar__categories-wrapper sidebar__single wow fadeInUp animated">
                            <h4 className="sidebar__categories-title sidebar__title">{data.sidebarTitle}</h4>
                            <ul className="sidebar__categories list-unstyled">
                                {data.sidebarItems.map((s, index) => {
                                    return (
                                        <li key={index}>
                                            <a href={s.url}>
                                                <span className="sidebar__categories__text">
                                                    <span className="sidebar__categories__icon">
                                                        <i className="fas fa-caret-right"></i>
                                                    </span>
                                                    {s.title}
                                                </span>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </aside>
                </div>
            </div>
            <div dangerouslySetInnerHTML={{__html: data.content}}
                 className={"col-md-" + (12 - data.sidebarColsDesktop) + " col-xs-" + (data.sidebarColsMobile === 12 ? 12 : 12 - data.sidebarColsMobile)}>
            </div>
        </div>
    )
}

export default ElementSidebarContent