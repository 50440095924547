import {useState} from "react";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import Menu from "./Menu/Menu";
import Footer from "./Footer/Footer";
import Banner from "./Banner/Banner";

const ThemeManagement = () => {

    const [activeTab, _setActiveTab] = useState(useParams()["*"]);
    const navigate = useNavigate();

    const setActiveTab = (tabName) => {
        _setActiveTab(tabName);
        navigate("/management/theme/" + tabName);
    }


    return (
        <div className={"row"}>

            <ul className="acn-theme-breadcrumb list-unstyled">
                <li><a href="/management">Yönetim Paneli</a></li>
                <li><span>Tema Yönetimi</span></li>
            </ul>

            <div className={"col-12"}>
                <ul className="nav nav-tabs mb-3 homepage-tabs" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("banner")}
                           className={"nav-link " + (activeTab === 'banner' ? 'active' : '')}>Banner</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("menu")}
                           className={"nav-link " + (activeTab === 'menu' ? 'active' : '')}>Menü</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a onClick={() => setActiveTab("footer")}
                           className={"nav-link " + (activeTab === 'footer' ? 'active' : '')}>Footer</a>
                    </li>
                </ul>
                <div className="tab-content" id="ex1-content">
                    <div className={"tab-pane fade menu show active"}>
                        <Routes>
                            <Route path={'banner'} element={<Banner/>}></Route>
                            <Route path={'menu'} element={<Menu/>}></Route>
                            <Route path={'footer'} element={<Footer/>}></Route>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThemeManagement