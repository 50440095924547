import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import "./EditorFeature.css"
import ElementFeature from "../../../../../Pages/PageElements/ElementFeature/ElementFeature";
import FontPicker from "../../FontPicker/FontPicker";
import TextEditor from "../../TextEditor/TextEditor";

const EditorFeature = (props) => {

    const [zoomRatio, setZoomRatio] = useState(false);
    const divWidthRef = useRef();
    const containerWidth = 1200;

    const [editingFeatureIndex, setEditingFeatureIndex] = useState(null);
    const [editingFeatureIcon, setEditingFeatureIcon] = useState("");
    const [editingFeatureTitle, setEditingFeatureTitle] = useState("");
    const [editingFeatureContent, setEditingFeatureContent] = useState("");


    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / containerWidth);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);


    const [form, setForm] = useState({
        content: '',
        features: [],
        layoutColsDesktop: 3,
        layoutColsMobile: 6,
        overFlowOption : "center"
    });

    const layoutColsDesktopOptions = [
        {value: 2, label: '%16.5'},
        {value: 3, label: '%25'},
        {value: 4, label: '%33'}
    ]

    const layoutColsMobileOptions = [
        {value: 4, label: '%33'},
        {value: 6, label: '%50'},
        {value: 12, label: '%100'}
    ]

    const overFlowOptions = [
        {value: "center", label: 'Ortala'},
        {value: "flex-start", label: 'Sola Yasla'},
    ]


    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }

    useEffect(() => {
        if (props.hasOwnProperty("data")) {
            setForm({...form, ...props.data});
        }
    }, [props]);

    const editFeature = (index) => {
        setEditingFeatureTitle(form.features[index].title);
        setEditingFeatureContent(form.features[index].content);
        setEditingFeatureIcon(form.features[index].icon);
        setEditingFeatureIndex(index);
    }

    const addFeature = () => {
        setEditingFeatureIndex(-1);
        setEditingFeatureIcon('');
        setEditingFeatureTitle('');
        setEditingFeatureContent('');
    }

    const deleteFeature = (index) => {
        setForm(prevForm => {
            const updatedFeatures = prevForm.features.filter((_, i) => i !== index);
            return {...prevForm, features: updatedFeatures};
        });
        setEditingFeatureIndex(null);
    };

    const saveFeature = () => {
        setForm(prevForm => {
            const updatedFeatures = editingFeatureIndex > -1
                ? prevForm.features.map((feature, i) => i === editingFeatureIndex ? {
                    icon: editingFeatureIcon,
                    title: editingFeatureTitle,
                    content: editingFeatureContent
                } : feature)
                : [...prevForm.features, {icon : editingFeatureIcon,title: editingFeatureTitle, content: editingFeatureContent}];

            return {...prevForm, features: updatedFeatures};
        });
        setEditingFeatureIndex(null);
    };
    return (
        <div>
            <div className={"row mb-30"}>
                <div className={"col-md-12"} ref={divWidthRef}>
                    <label className={"form-label"}>Önizleme</label>
                </div>
                <div className={"col-md-12"} style={{zoom: zoomRatio}}>
                    <ElementFeature data={form}></ElementFeature>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Nesne Düzeni (Masaüstü)</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={layoutColsDesktopOptions.find(c => c.value === form.layoutColsDesktop)}
                                onChange={(selectedOption) => handleForm("layoutColsDesktop", selectedOption.value)}
                                options={layoutColsDesktopOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
                <div className={"col-md-4"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Nesne Düzeni (Mobil)</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={layoutColsMobileOptions.find(c => c.value === form.layoutColsMobile)}
                                onChange={(selectedOption) => handleForm("layoutColsMobile", selectedOption.value)}
                                options={layoutColsMobileOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
                <div className={"col-md-4"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Artan Nesneleri</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={overFlowOptions.find(c => c.value === form.overFlowOption)}
                                onChange={(selectedOption) => handleForm("overFlowOption", selectedOption.value)}
                                options={overFlowOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
            </div>

            <div className={"text-right mt-4"}>
                <a className="btn btn-secondary" onClick={() => addFeature()}><i
                    className={"fa fa-plus mx-1"}></i> Yeni Ekle</a>
            </div>
            <table className={"table management__table mt-4"}>
                <thead>
                <tr>
                    <th>Nesne Başlığı</th>
                    <th>Operasyonlar</th>
                </tr>
                </thead>
                <tbody>
                {form.features.map((feature, index) => {
                        return (
                            <tr key={index}>
                                <td>{feature.title}</td>
                                <td className="text-right">
                                    <a onClick={() => editFeature(index)}
                                       className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                    <a onClick={() => {
                                        deleteFeature(index)
                                    }} className="btn btn-outline-danger btn-sm mx-1 my-1">Sil</a>
                                </td>
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>


            <div className={"modal-footer"}>
                <div className={"col-md-12 text-right"}>
                    <a className="btn btn-secondary mx-2" onClick={() => props.closeElementEditor()}><i
                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                    <a className="btn btn-secondary" onClick={() => props.saveElement(form)}><i
                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                </div>
            </div>


            {
                editingFeatureIndex !== null &&
                <div className={"modal"} id="staticBackdrop">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Nesne {editingFeatureIndex === -1 ? 'Ekle' : 'Düzenle'}</h5>
                                <button type="button" className="btn-close"
                                        onClick={() => setEditingFeatureIndex(null)}></button>
                            </div>
                            <div className="modal-body">

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Icon</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <FontPicker onChange={(e) => setEditingFeatureIcon(e)} value={editingFeatureIcon}/>

                                </div>


                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Nesne Başlığı</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <input className="form-control" value={editingFeatureTitle}
                                           onChange={(e) => setEditingFeatureTitle(e.target.value)}/>
                                </div>

                                <div className={"col-md-12 mt-4"}>
                                    <label className={"form-label"}>Nesne İçeriği</label>
                                </div>

                                <div className={"col-md-12 "}>
                                    <TextEditor height={200} onEditorChange={(e) => setEditingFeatureContent(e)}
                                                initialValue={editingFeatureContent}></TextEditor>
                                </div>

                                <div className={"col-md-12 text-right"}>
                                    <a className="btn btn-secondary mx-2" onClick={() => setEditingFeatureIndex(null)}><i
                                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                                    <a className="btn btn-secondary" onClick={() => saveFeature()}><i
                                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
        ;
}

export default EditorFeature;