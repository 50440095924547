import BaseService from './BaseService';

class ManagementBlogDetailService extends BaseService {

    getBlogDetails(search,page) {
        return this.apiClient.get('HomePageManagement/Blog?search='+search+'&page='+page);
    }
    saveBlogDetail(blogDetail) {
        return this.apiClient.post('HomePageManagement/Blog',blogDetail);
    }
    getBlogDetail(blogDetailId) {
        return this.apiClient.get('HomePageManagement/Blog/'+blogDetailId);
    }
    deleteBlog(blogDetailId) {
        return this.apiClient.delete('HomePageManagement/Blog/'+blogDetailId);
    }

}

export default new ManagementBlogDetailService();