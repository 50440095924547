import React, {useEffect, useRef, useState} from "react";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import BlogItem from "./BlogItem/BlogItem";
import HomePageService from "../../Services/HomePageService";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Blog.css"
import {Helmet} from "react-helmet-async";
import {useConfig} from "../../Services/ConfigContext";

const Blog = () => {

    const divWidthRef = useRef();
    const [masonryActive,setMasonryActive] = useState(false);

    const handleResize = () => {
        if (divWidthRef.current) {
            setMasonryActive(divWidthRef.current.clientWidth > 768)
        }
    }

    useEffect(() => {
        window.addEventListener("resize",handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize",handleResize)
        }
    },[])


    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState("");

    const page = useRef(1);
    const [totalResults, setTotalResults] = useState(0);
    const [search, setSearch] = useState("");

    let searchTimeout = null;

    const [hasMore, setHasMore] = useState(true);

    const clearFilters = () => {
        setSearch("");
        setActiveCategory("");
        page.current = 1;
    }

    const getData = () => {
        HomePageService.getBlogList(search, activeCategory, page.current).then(response => {
            setBlogs((oldBlogs) => [...oldBlogs, ...response.data.blogs]);
            if (response.data.blogs.length === 0) {
                setHasMore(false);
            } else {
                setHasMore(true)
            }
            setCategories(response.data.categories);
            setTotalResults(response.data.totalResults);
            page.current = page.current+1;
        })
    }

    const onSearch = (val) => {
        if(searchTimeout){
            clearTimeout(searchTimeout);
        }

        searchTimeout = setTimeout(() => {
            setSearch(val)
        },500);
    }

    useEffect(() => {
        setBlogs([]);
        if(page.current > 1) {
            page.current = 1;
        }
        getData();
    }, [activeCategory, search]);


    const config = useConfig();
    const bannerData = config.banner;


    return (<div className="page-wrapper">
            {bannerData &&  <Helmet>
                <title>{bannerData.metaTitle} - Blog</title>
                <meta name="description" content={bannerData.metaDescription}/>
            </Helmet>
            }
            <div className="sc_title sc_title_default mb-5 sticky-top position-sticky shop-title-holder">
                <div className="sc_item_title_wrap sc_align_center sc_position_below sc_subtitle_present"><h2
                    className="sc_item_title sc_title_title sc_item_title_style_default"><span
                    className="sc_item_title_line_left"></span><span className="sc_item_title_text">Blog</span><span
                    className="sc_item_title_line_right"></span></h2></div>
            </div>
            <div className={"container sticky-top shop-filter-holder mb-4"}>

                <div className={"row"}>
                    <div className={"col-12 col-md-6 m-auto"}>
                        <input onChange={(e) => onSearch(e.target.value)} type={"text"}
                               placeholder={"Arama yapın..."} className={"category-holder w-100"}/>
                    </div>
                </div>

                <div className={"row"}>

                    <div className={"col-12 col-md-8 d-flex justify-content-center m-auto flex-wrap"}>
                        {categories.map((c, index) => {
                            return <a key={index}
                                      className={"category-holder " + (activeCategory === c ? 'active' : '')}
                                      onClick={() => setActiveCategory(c)}>{c}</a>
                        })}
                        <a className={"category-holder " + (activeCategory === "" ? 'active' : '')}
                           onClick={() => setActiveCategory("")}>Tümünü Göster</a>
                    </div>

                    <div className={"row mt-2"}>
                        <div className={"w-auto m-auto"}>
                            {totalResults > 0 ?
                                <div/>
                                :
                                <small>Aradığınız kriterlere uygun blog yazısı bulunamadı</small>
                            }
                            {(search !== "" || activeCategory !== "") &&

                                <a className={"category-holder"} onClick={() => clearFilters()}><i
                                    className={"fa fa-remove"}></i> Arama kriterlerini temizle</a>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <div className={"container"} ref={divWidthRef}>
                {!masonryActive &&
                <InfiniteScroll className={"container"}
                                dataLength={blogs.length}
                                next={() => getData()}
                                hasMore={hasMore}>
                        {blogs.map((blog,i)=> {
                            return <BlogItem data={blog} key={i}/>
                        })}
                </InfiniteScroll>

                }

                {masonryActive &&
                <ResponsiveMasonry  >
                    <InfiniteScroll className={"container"}
                                    dataLength={blogs.length}
                                    next={() => getData()}
                                    hasMore={hasMore}>
                        <Masonry>
                            {blogs.map((blog,i)=> {
                                return <BlogItem data={blog} key={i}/>
                            })}

                        </Masonry>
                    </InfiniteScroll>
                </ResponsiveMasonry>
                }
            </div>
        </div>
    )
}

export default Blog;