import './UsersManagement.css'
import Search from "../Layout/Search/Search";
import Paginator from "../Layout/Paginator/Paginator";
import {useEffect, useState} from "react";
import ManagementUsersService from "../../Services/ManagementUsersService";
import UserEdit from "./UserEdit/UserEdit";
import swal from "sweetalert2";
import toast from "react-hot-toast";
import UserPasswordChange from "./UserPasswordChange/UserPasswordChange";

const UsersManagement = () => {

    const [userEditModalState, setUserEditModalState] = useState(false);
    const [passwordModalState, setPasswordModalState] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [passworChangingUser, setPasswordChangingUser] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [pageValue, setPageValue] = useState(1);
    const [data, setData] = useState({
        total: 0,
        result: []
    });

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const onPageChange = (value) => {
        setPageValue(value);
    }

    const modalOnClose = () => {
        getData();
        setUserEditModalState(false);
        setPasswordModalState(false);
    }

    const editUser = (id = null) => {
        if (id) {
            ManagementUsersService.getUser(id).then(response => {
                  setEditingUser(response.data);
              })
        } else {
            setEditingUser(null);
        }
        setUserEditModalState(true);
    }

    const changePassword = (id) => {
        setPasswordChangingUser(id);
        setPasswordModalState(true);
    }
    const deleteDialog = (user) => {
        swal.fire({
            title: "İşleme devam et",
            html: "<b>"+user.name + "</b> isimli kullanıcı silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                ManagementUsersService.deleteUser(user.id).then(response => {
                    toast.success('Kullanıcı silindi');
                    getData();
                }).catch(e => {
                    toast.error(e.response.data);
                })
            }
        })
    }

    const getData = () => {
        ManagementUsersService.getUsers(searchValue, pageValue).then(response => {
            setData(response.data);
        });
    }

    useEffect(() => {
        getData();
    }, [searchValue, pageValue]);

    return (
        <div className="row">
            <ul className="acn-theme-breadcrumb list-unstyled">
                <li><a href="/management">Yönetim Paneli</a></li>
                <li><span>Kullanıcı Yönetimi</span></li>
            </ul>

            <div className="col-12 d-flex">
                <div className="col-10 pr-20">
                    <Search onSearch={onSearch}></Search>
                </div>
                <div className="col-2 d-flex">
                    <a className="btn text-center px-3 py-2 mx-auto mr-0" onClick={() => editUser(null)}>Kullanıcı Ekle</a>
                </div>
            </div>
            <div className="col-12 mt-4 mb-10">
                <table className="table management__table">
                    <thead>
                    <tr>
                        <th>Kullanıcı</th>
                        <th>E-Posta Adresi</th>
                        <th width={20}>Rol</th>
                        <th>Operasyonlar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.result.map(d => {
                            return (
                                <tr key={d.id}>
                                    <td width={150}>
                                        {d.name}
                                    </td>
                                    <td width={200}>
                                        {d.email}
                                    </td>
                                    <td width={190}>
                                        {d.roles.join(", ")}
                                    </td>
                                    <td className="text-right">
                                        <a onClick={() => editUser(d.id)}
                                           className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                        <a onClick={() => changePassword(d.id)}
                                           className="btn btn-outline-success btn-sm mx-1 my-1">Şifreyi değiştir</a>
                                        <a onClick={() => {
                                            deleteDialog(d)
                                        }} className="btn btn-outline-danger btn-sm mx-1 my-1">Kullanıcıyı Sil</a>
                                    </td>
                                </tr>
                            )
                        })
                    }

                    </tbody>
                </table>
            </div>
            <Paginator totalResult={data.total} currentPage={pageValue} onPageChange={onPageChange}></Paginator>

            {
                userEditModalState &&
                <UserEdit onClose={modalOnClose} user={editingUser}></UserEdit>
            }
            {
                passwordModalState &&
                <UserPasswordChange onClose={modalOnClose} user={passworChangingUser}></UserPasswordChange>
            }

        </div>
    )
}

export default UsersManagement