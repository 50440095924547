import "./Sidebar.css"
import {useAuth} from "../../../Services/AuthContext";
import {useNavigate} from "react-router-dom";

const Sidebar = () => {

    const navigate = useNavigate();
    const {loggedUser, setToken} = useAuth()

    const logout = () => {
        setToken(null);
    }

    return (
        <div className="insurance-sidebar">
            <div className="insurance-sidebar__single">
                <div className={"col-12 d-flex mb-2"}>
                    <span className={"m-auto"}>
                        <span>Hoşgeldiniz, </span>
                        <b>{loggedUser?.given_name}</b>
                        </span>
                </div>
                <div className={"col-12 d-flex"}>
                    <a onClick={() => logout()} className={"btn m-auto btn-outline-danger"}>Çıkış Yap</a>
                </div>
                <hr/>
                <ul className="list-unstyled insurance-sidebar__nav">
                    <li><a onClick={() => navigate("/management/theme/banner")}>Tema</a></li>
                    <li><a onClick={() => navigate("/management/homepage/layout")}>Anasayfa</a></li>
                    <li><a onClick={() => navigate("/management/users")}>Kullanıcılar</a></li>
                    <li><a onClick={() => navigate("/management/products")}>Ürünler</a></li>
                    <li><a onClick={() => navigate("/management/educations")}>Eğitimler</a></li>
                    <li><a onClick={() => navigate("/management/pages")}>Sayfalar</a></li>
                    <li><a onClick={() => navigate("/management/blog")}>Blog</a></li>
                    <li><a onClick={() => navigate("/management/contact")}>İletişim ve Mesajlar</a></li>
                </ul>
            </div>
        </div>
    )
}
export default Sidebar