import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import HomePage from "./Pages/HomePage/HomePage";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Topbar from "./Pages/Layout/Topbar/Topbar";
import Header from "./Pages/Layout/Header/Header";
import Footer from "./Pages/Layout/Footer/Footer";
import Management from "./Management/Management";
import ManagementLogin from "./Login/Management/ManagementLogin";
import React, {useContext, useEffect} from "react";
import Contact from "./Pages/Contact/Contact";
import PageRenderer from "./Pages/PageRenderer/PageRenderer";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ProductList from "./Pages/Products/ProductList";
import ProductDetail from "./Pages/Products/ProductDetail/ProductDetail";
import EducationList from "./Pages/Educations/EducationList";
import EducationDetail from "./Pages/Educations/EducationDetail/EducationDetail";
import Blog from "./Pages/Blog/Blog";
import Loader from "./Pages/Layout/Utils/Loader/Loader";
import LoaderEventEmitter from "./Pages/Layout/Utils/Loader/LoaderEventEmitter";
import {LoaderContext} from "./Services/LoaderContext";
import BlogDetail from "./Pages/Blog/BlogDetail/BlogDetail";
import {useConfig} from "./Services/ConfigContext";

function App() {


    const {showLoader,hideLoader} = useContext(LoaderContext);

    useEffect(() => {
        const showLoaderHandler = (infoMessage) => {
            showLoader(infoMessage);
        };

        const hideLoaderHandler = () => {
           hideLoader();
        };

        LoaderEventEmitter.on('showLoader', showLoaderHandler);
        LoaderEventEmitter.on('hideLoader', hideLoaderHandler);
        return () => {
            LoaderEventEmitter.off('showLoader', showLoaderHandler);
            LoaderEventEmitter.off('hideLoader', hideLoaderHandler);
        };
    }, []);

    const config = useConfig();
    const bannerData = config.banner;
    return (
        <HelmetProvider>
            {bannerData &&  <Helmet>
                <title>{bannerData.metaTitle}</title>
                <meta name="description" content={bannerData.metaDescription}/>
            </Helmet>
            }
            <Router>
                <div className="page-wrapper">
                    <Topbar></Topbar>
                    <Header></Header>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/blog" element={<Blog/>}/>
                        <Route path="/blog/*" element={<BlogDetail/>}/>
                        <Route path="/magaza" element={<ProductList/>}/>
                        <Route path="/magaza/*" element={<ProductDetail/>}/>
                        <Route path="/egitimler" element={<EducationList/>}/>
                        <Route path="/egitimler/*" element={<EducationDetail/>}/>
                        <Route path="/iletisim" element={<Contact/>}/>
                        <Route path={'/login'} element={<ManagementLogin/>}/>
                        <Route path="/management/*" element={<Management/>}/>
                        <Route path="*" element={<PageRenderer/>}/>
                    </Routes>
                    <Footer></Footer>
                    <Loader/>
                </div>
            </Router>
        </HelmetProvider>
    );
}

export default App;
