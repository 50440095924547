import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import ManagementHomePageService from "../../../../Services/ManagementHomePageService";
import BaseService from "../../../../Services/BaseService";
const EducationsPageContent = (props) => {

    const [form, setForm] = useState({
        header: '',
        title: '',
        educationTitle : '',
        educationButtonTitle : '',
        background : '',
    });


    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }


    const save = () => {
        ManagementHomePageService.savePageContent("Education",JSON.stringify(form)).then(response => {
            toast.success('İçerik kaydedildi');
            props.onClose()
        })
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            var baseService = new BaseService();
            baseService.uploadImage(selectedFile).then(response => {
                var currentForm = form;
                currentForm.background = response.data;
                setForm(currentForm);
            })
        }
    };

    useEffect(() => {
        ManagementHomePageService.getPageContent("Education").then(response => {
            setForm({...form, ...response.data});
        })
    }, []);

    return (
        <div className={"modal modal-lg"} id="staticBackdrop">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Bölüm Başlıkları ve Özellikleri</h5>
                        <button type="button" className="btn-close" onClick={() => props.onClose()}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">

                            <div className={"col-md-12 d-flex mt-2"}>
                                <label className={"form-label col-3 text-left my-auto"}>Ana Başlık</label>
                            </div>
                            <div className={"col-md-12 mt-2"}>
                                <input className={"form-control"} onChange={(e) => handleForm("header", e.target.value)}
                                       value={form.header}></input>
                            </div>

                            <div className={"col-md-12 d-flex mt-2"}>
                                <label className={"form-label col-12 text-left my-auto"}>Ana Başlık Alt Metin</label>
                            </div>
                            <div className={"col-md-12 mt-2"}>
                                <input className={"form-control"} onChange={(e) => handleForm("title", e.target.value)}
                                       value={form.title}></input>
                            </div>

                            <div className={"col-md-12 d-flex mt-2"}>
                                <label className={"form-label col-12 text-left my-auto"}>Eğitim Yönlendirmesi Üst Başlık</label>
                            </div>
                            <div className={"col-md-12 mt-2"}>
                                <input className={"form-control"} onChange={(e) => handleForm("shopTitle", e.target.value)}
                                       value={form.shopTitle}></input>
                            </div>

                            <div className={"col-md-12 d-flex mt-2"}>
                                <label className={"form-label col-12 text-left my-auto"}>Eğitim Yönlendirmesi Buton Başlık</label>
                            </div>
                            <div className={"col-md-12 mt-2"}>
                                <input className={"form-control"} onChange={(e) => handleForm("shopButtonTitle", e.target.value)}
                                       value={form.shopButtonTitle}></input>
                            </div>

                            <div className={"col-md-12 d-flex mt-2"}>
                                <label className={"form-label col-12 text-left my-auto"}>Eğitim Arkaplan Fotoğrafı</label>
                            </div>
                            <div className={"col-md-12 mt-2"}>
                                <input
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    onChange={(e) => handleFileChange(e)}
                                />
                            </div>

                            <div className={"col-12 text-right mt-4"}>
                                <a onClick={() => props.onClose()} className="btn btn-secondary mx-2"><i
                                    className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                                <a onClick={() => save()} className="btn btn-secondary"><i
                                    className={"fa fa-save mx-1"}></i> Kaydet</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EducationsPageContent