import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import "./EditorPhotoContent.css"
import BaseService from "../../../../../Services/BaseService";
import ElementPhotoContent from "../../../../../Pages/PageElements/ElementPhotoContent/ElementPhotoContent";
import TextEditor from "../../TextEditor/TextEditor";

const EditorPhotoContent = (props) => {

    const [zoomRatio, setZoomRatio] = useState(false);
    const divWidthRef = useRef();
    const containerWidth = 1200;

    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / containerWidth);
        }
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);


    const [form, setForm] = useState({
        imagePosition: 'left', //none, left, right
        imageColsDesktop: 6, // 2-8
        imageColsMobile: 12, // 6-12
        image: '',
        content: '',
        imageType: 'image' //image,video
    });

    const imagePositionOptions = [
        {value: 'left', label: 'Sol'},
        {value: 'right', label: 'Sağ'},
        {value: 'none', label: 'Fotoğraf/Video Yok'},
    ]

    const imageTypeOptions = [
        {value: 'image', label: 'Fotoğraf'},
        {value: 'video', label: 'Video (YouTube)'}
    ]

    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const fileType = selectedFile.type;
            const validTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];

            if (validTypes.includes(fileType)) {
                var baseService = new BaseService();
                baseService.uploadImage(selectedFile).then(response => {
                    setForm({...form, image: response.data});
                })
            }
        }
    };

    useEffect(() => {
        if (props.hasOwnProperty("data")) {
            setForm({...form, ...props.data});
        }
    }, [props]);


    return (
        <div>
            <div className={"row mb-30"}>
                <div className={"col-md-12"} ref={divWidthRef}>
                    <label className={"form-label"}>Önizleme</label>
                </div>
                <div className={"col-md-12"} style={{zoom: zoomRatio}}>
                    <ElementPhotoContent data={form}></ElementPhotoContent>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-6 mb-4"}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Fotoğraf/Video Pozisyonu</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={imagePositionOptions.find(ipo => ipo.value === form.imagePosition)}
                                onChange={(selectedOption) => handleForm("imagePosition", selectedOption.value)}
                                options={imagePositionOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
                <div className={"col-md-6 mb-4"} style={{display: form.imagePosition === "none" ? "none" : "block"}}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Görsel Türü</label>
                    </div>
                    <div className={"col-md-12"}>
                        <Select isSearchable={false}
                                isMulti={false}
                                value={imageTypeOptions.find(ipo => ipo.value === form.imageType)}
                                onChange={(selectedOption) => handleForm("imageType", selectedOption.value)}
                                options={imageTypeOptions}
                                classNamePrefix="form-control-select"/>
                    </div>
                </div>
                <div className={"col-md-6 mb-4"} style={{display: form.imagePosition === "none" ? "none" : "block"}}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Fotoğraf/Video ve İçerik Oranı (Masaüstü)</label>
                    </div>
                    <div className={"col-md-12 d-flex"}>
                        {
                            [...Array(6)].map((v, i) => {
                                return <div onClick={() => handleForm("imageColsDesktop", (i + 1) * 2)} key={i}
                                            className={"grid-template d-flex col-md-1 " + (form.imageColsDesktop >= (i + 1) * 2 ? 'active-grid-template' : '')}>
                                    {
                                        form.imageColsDesktop >= (i + 1) * 2 &&
                                        <i className={"fa fa-image text-white m-auto"}></i>
                                    }
                                    {
                                        form.imageColsDesktop < (i + 1) * 2 &&
                                        <i className={"fa fa-pencil m-auto"}></i>
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className={"col-md-6 mb-4"} style={{display: form.imagePosition === "none" ? "none" : "block"}}>
                    <div className={"col-md-12"}>
                        <label className={"form-label"}>Fotoğraf/Video ve İçerik Oranı (Mobil)</label>
                    </div>
                    <div className={"col-md-12 d-flex"}>
                        {
                            [...Array(2)].map((v, i) => {
                                return <div onClick={() => handleForm("imageColsMobile", (i + 1) * 6)} key={i}
                                            className={"grid-template d-flex col-md-1 " + (form.imageColsMobile >= (i + 1) * 6 ? 'active-grid-template' : '')}>
                                    {
                                        form.imageColsMobile >= (i + 1) * 6 &&
                                        <i className={"fa fa-image text-white m-auto"}></i>
                                    }
                                    {
                                        form.imageColsMobile < (i + 1) * 6 &&
                                        <i className={"fa fa-pencil m-auto"}></i>
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={"row mb-4"}
                 style={{display: (form.imagePosition !== "none" && form.imageType === "image") ? "block" : "none"}}>
                <div className={"col-md-12"}>
                    <label className={"form-label"}>Fotoğraf</label>
                </div>
                <div className={"col-md-12"}>
                    <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={(e) => handleFileChange(e)}
                        accept=".png, .jpg, .jpeg, .webp"
                    />
                </div>
            </div>
            <div className={"row mb-4"}
                 style={{display: (form.imagePosition !== "none" && form.imageType === "video") ? "block" : "none"}}>
                <div className={"col-md-12"}>
                    <label className={"form-label"}>Video URL</label>
                </div>
                <div className={"col-md-12"}>
                    <input className="form-control" value={form.image}
                           onChange={(e) => handleForm("image", e.target.value)}/>
                </div>
            </div>


            <div className={"row mb-4"}>
                <div className={"col-md-12"}>
                    <label className={"form-label"}>İçerik</label>
                </div>

                <div className={"col-md-12 "}>
                    <TextEditor onEditorChange={(e) => handleForm("content", e)}
                                initialValue={form.content}></TextEditor>
                </div>
            </div>
            <div className={"modal-footer"}>
                <div className={"col-md-12 text-right"}>
                    <a className="btn btn-secondary mx-2" onClick={() => props.closeElementEditor()}><i
                        className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                    <a className="btn btn-secondary" onClick={() => props.saveElement(form)}><i
                        className={"fa fa-save mx-1"}></i> Kaydet</a>
                </div>
            </div>
        </div>
    )
}

export default EditorPhotoContent;