import React, {useEffect, useRef, useState} from "react";
import swal from "sweetalert2";
import toast from "react-hot-toast";
import ManagementHomePageService from "../../../Services/ManagementHomePageService";
import PromotePageContent from "./PageContent/PromotePageContent";
import PromoteEdit from "./PromoteEdit/PromoteEdit";
import {ReactSortable} from "react-sortablejs";


const Promote = () => {

    const [pageContentModalState, setPageContentModalState] = useState(false)
    const [editingPromote, setEditingPromote] = useState(null);
    const [promoteSorting, setPromoteSorting] = useState(false);

    const onPromoteEditorClose = () => {
        setEditingPromote(null);
        setPageContentModalState(false)
        getData();
    }

    const [data, setData] = useState(null);
    const deleteDialog = (itemId) => {
        swal.fire({
            title: "İşleme devam et",
            html: "Nesne silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                ManagementHomePageService.deletePromote(itemId).then(response => {
                    toast.success('Nesne silindi');
                    getData();
                }).catch(e => {
                    toast.error(e.response.data);
                })
            }
        })
    }

    const getData = () => {
        ManagementHomePageService.getPromotes().then(response => {
            setData(response.data
                .sort((a, b) => a.order > b.order ? 1 : -1));
        });
    }

    const savePromoteSorting = () => {
        let sortCount = 0;
        let newSortList = data.map((d) => {
            sortCount++;
            return {
                id: d.id,
                order: sortCount
            }
        })
        ManagementHomePageService.savePromoteSorts(newSortList).then(response => {
            toast.success("Sıralama kaydedildi");
            setPromoteSorting(false);
        });
    }

    const getPositionText = (position) => {
        if(position === "left"){
            return "Sol";
        }else if(position === "right"){
            return "Sağ Üst";
        }if(position === "bottom"){
            return "Sağ Alt";
        }
    }

    useEffect(() => {
        if (!promoteSorting) {
            getData();
        }
    }, [promoteSorting]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="col-12 mb-10">
            {editingPromote === null &&

                <div className={"col-12 mt-2"}>
                    <div className={"text-right p-0 mb-2"}>
                        {!promoteSorting &&
                            <a onClick={() => setPromoteSorting(true)} className="btn btn-secondary mx-1"><i
                                className={"fa fa-sort mx-1"}></i> Sıralamayı Etkinleştir</a>}
                        {promoteSorting &&
                            <a onClick={() => setPromoteSorting(false)} className="btn btn-secondary mx-1"><i
                                className={"fa fa-cancel mx-1"}></i> Sıralamayı İptal Et</a>}
                        {promoteSorting && <a onClick={() => savePromoteSorting()} className="btn btn-secondary mx-1"><i
                            className={"fa fa-check mx-1"}></i> Sıralamayı Kaydet</a>}
                        <a onClick={() => setEditingPromote(-1)} className="btn btn-secondary mx-1"><i
                            className={"fa fa-plus"}></i> Nesne Ekle</a>
                        <a onClick={() => setPageContentModalState(true)} className="btn btn-secondary mx-1"><i
                            className={"fa fa-pencil"}></i> Bölüm Başlıklarını Düzenle</a>
                    </div>
                    <table className="table management__table">
                        <thead>
                        <tr>
                            <th>Başlık</th>
                            <th>Bölüm</th>
                            <th>Operasyonlar</th>
                        </tr>
                        </thead>
                        {data !== null &&
                        <ReactSortable list={data} setList={setData} disabled={!promoteSorting} tag={"tbody"}>
                            {
                                data.map(d => {
                                    return (
                                        <tr key={d.id} draggable={true}>
                                            <td>
                                                {d.title}
                                            </td>
                                            <td width={100}>
                                                {getPositionText(d.position)}
                                            </td>
                                            <td className="text-right">
                                                <a onClick={() => setEditingPromote(d.id)}
                                                   className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                                <a onClick={() => {
                                                    deleteDialog(d.id)
                                                }} className="btn btn-outline-danger btn-sm mx-1 my-1">Nesneyi Sil</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </ReactSortable>
                        }
                    </table>
                </div>
            }


            {editingPromote != null &&
                <PromoteEdit promoteId={editingPromote} onClose={() => onPromoteEditorClose()}></PromoteEdit>
            }

            {pageContentModalState &&
                <PromotePageContent data={data.pageContent}
                                    onClose={() => onPromoteEditorClose(false)}></PromotePageContent>
            }
        </div>
    )
}

export default Promote