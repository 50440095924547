import React, {useEffect, useState} from "react";
import swal from "sweetalert2";
import toast from "react-hot-toast";
import ManagementHomePageService from "../../../Services/ManagementHomePageService";
import FeatureEdit from "./FeatureEdit/FeatureEdit";
import {ReactSortable} from "react-sortablejs";


const Feature = () => {

    const [editingFeature, setEditingFeature] = useState(null);

    const [featureSorting, setFeatureSorting] = useState(false);


    const onFeatureEditorClose = () => {
        setEditingFeature(null);
        getData();
    }

    const [data, setData] = useState([]);
    const deleteDialog = (featureId) => {
        swal.fire({
            title: "İşleme devam et",
            html: "Nesne, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                ManagementHomePageService.deleteFeature(featureId).then(response => {
                    toast.success('Feature silindi');
                    getData();
                }).catch(e => {
                    toast.error(e.response.data);
                })
            }
        })
    }

    const getData = () => {
        ManagementHomePageService.getFeatures().then(response => {
            setData(response.data
                .sort((a, b) => a.order > b.order ? 1 : -1));
        });
    }

    const saveFeatureSorting = () => {
        let sortCount = 0;
        let newSortList = data.map((d) => {
            sortCount++;
            return {
                id: d.id,
                order: sortCount
            }
        })
        ManagementHomePageService.saveFeatureSorts(newSortList).then(response => {
            toast.success("Sıralama kaydedildi");
            setFeatureSorting(false);
        });
    }

    useEffect(() => {
        if (!featureSorting) {
            getData();
        }
    }, [featureSorting]);

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="col-12 mb-10">
            {editingFeature != null &&
                <FeatureEdit featureId={editingFeature} onClose={() => onFeatureEditorClose()}></FeatureEdit>}
            {editingFeature === null &&
                <div className={"col-12 mt-2"}>
                    <div className={"text-right p-0 mb-2"}>
                        {!featureSorting &&
                            <a onClick={() => setFeatureSorting(true)} className="btn btn-secondary mx-2"><i
                                className={"fa fa-sort mx-1"}></i> Sıralamayı Etkinleştir</a>}
                        {featureSorting &&
                            <a onClick={() => setFeatureSorting(false)} className="btn btn-secondary mx-2"><i
                                className={"fa fa-cancel mx-1"}></i> Sıralamayı İptal Et</a>}
                        {featureSorting && <a onClick={() => saveFeatureSorting()} className="btn btn-secondary mx-2"><i
                            className={"fa fa-check mx-1"}></i> Sıralamayı Kaydet</a>}
                        <a onClick={() => setEditingFeature(-1)} className="btn btn-secondary"><i
                            className={"fa fa-plus"}></i> Nesne Ekle</a>
                    </div>
                    <table className="table management__table">
                        <thead>
                        <tr>
                            <th>Başlık</th>
                            <th>Açıklama</th>
                            <th>Operasyonlar</th>
                        </tr>
                        </thead>
                        <ReactSortable list={data} setList={setData} disabled={!featureSorting} tag={"tbody"}>
                            {
                                data.map(d => {
                                    return (
                                        <tr key={d.id} draggable={true}>
                                            <td width={200}>
                                                {d.title}
                                            </td>
                                            <td >
                                                {d.description}
                                            </td>
                                            <td className="text-right">
                                                <a onClick={() => setEditingFeature(d.id)}
                                                   className="btn btn-outline-success btn-sm mx-1 my-1">Düzenle</a>
                                                <a onClick={() => {
                                                    deleteDialog(d.id)
                                                }} className="btn btn-outline-danger btn-sm mx-1 my-1">Nesneyi Sil</a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </ReactSortable>
                    </table>
                </div>
            }
        </div>
    )
}

export default Feature