import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import HomePageService from "../../../Services/HomePageService";
import {Helmet} from "react-helmet-async";
import PageContainer from "../../PageContainer/PageContainer";
import {useConfig} from "../../../Services/ConfigContext";

const BlogDetail = () =>{
    const pageUrl = useParams()["*"];
    const config = useConfig();
    const bannerData = config.banner;
    const [blogData, setBlogData] = useState(null);
    useEffect(() => {
        HomePageService.getBlog(pageUrl).then(response => {
            let data = response.data;
            if (data.hasOwnProperty("content")) {
                data.content = JSON.parse(data.content);
            }
            setBlogData(data);
        })
    }, []);

    return (blogData !== null &&
        <>
            {bannerData &&
            <Helmet>
                <title>{bannerData.metaTitle} - {blogData?.title}</title>
                <meta name="description" content={blogData?.metaDescription}/>
            </Helmet>
            }
            <PageContainer page={blogData}/>
        </>
    );
}

export default BlogDetail