import BaseService from './BaseService';

class ManagementContactService extends BaseService {

    getMessages(search,page) {
        return this.apiClient.get('HomePageManagement/Messages?search='+search+'&page='+page);
    }

    getMessage(id){
        return this.apiClient.get('HomePageManagement/Message/'+id);
    }
    deleteMessage(id){
        return this.apiClient.delete('/HomePageManagement/Message/'+id);
    }
    changeStatus(id,status){
        return this.apiClient.post('/HomePageManagement/Message/'+id+'/'+status);
    }

}

export default new ManagementContactService();