import "./ElementFaq.css"
import {useEffect, useState} from "react";
import React from 'react';

const ElementFaq = (props) => {

    const [activeFaq, setActiveFaq] = useState(null);

    const [data, setData] = useState({
        content: '',
        faqs: [],
        layoutCols: 6 // 6,12
    })

    useEffect(() => {
        if (props) {
            setData({...data, ...props.data})
        }
    }, [props]);

    return (
        <div className="faq-page__accordion col-12">
            <div className="faq-accordion acn-theme-accordion" data-grp-name="acn-theme-accordion">
                <div className="row">
                    {
                        data.faqs.map((f, index) => {
                            return (
                                <div key={index} className={"col-lg-" + data.layoutCols + " col-xs-12 mt-2"}>
                                    <div
                                        className={"accordion wow fadeInUp animated " + (activeFaq === index ? 'active' : '')}>
                                        <div className="accordion-title" onClick={() => activeFaq === index ? setActiveFaq(null) : setActiveFaq(index)}>
                                            <span className="accordion-title__icon"><i
                                                className="icon-arrow-up-right"></i></span>
                                            <h4>{f.title}</h4>
                                        </div>
                                        <div className="accordion-content"
                                             style={{display: (activeFaq === index ? 'block' : 'none')}}>
                                            <div className="inner"
                                                 dangerouslySetInnerHTML={{__html: f.content}}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>)
}

export default ElementFaq