import './Header.css'
import {v4 as uuidv4} from "uuid";
import {useEffect, useState} from "react";
import {useConfig} from "../../../Services/ConfigContext";
import React from 'react';

const Header = () => {

    const [activeUrl, setActiveUrl] = useState(null);

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const config = useConfig();
    const menu = config.menu;
    const data = config.banner;


    useEffect(() => {
        setActiveUrl(window.location.pathname.toLowerCase());
    }, [window.location.pathname]);

    const getMenuItem = (m) => {
        return <li key={uuidv4()}
                   className={"menu-item menu-item-type-custom menu-item-object-custom " + (((activeUrl === "/" && m.url === "/") || (m.url.length > 1 && activeUrl?.startsWith(m.url))) ? "current-menu-ancestor" : "")}>
            <a href={m.url} onClick={(e) => { setMenuIsOpen(false); return e;}}>{m.name}</a>
            {m.hasOwnProperty('children') && m.children !== null && m.children.length > 0 &&
                <ul>
                    {
                        m.children.map(c => {
                            return getMenuItem(c);
                        })
                    }
                </ul>
            }
        </li>
    }

    return (
        <div className="page-wrapper page-wrapper-menu mb-0 mb-md-5 sticky-xs-top position-relative ">
            <div className={"px-2 pt-2 d-flex mobile-menu-holder justify-content-between d-block d-md-none"}>
                <span className={"active-page-mobile my-auto px-3"}>Menü</span>
                <a className={"sc_button sc_button_default sc_button_size_small fs-6 px-4"} onClick={() => setMenuIsOpen(true)}><i className={"fa fa-bars"}></i> </a>
            </div>

            {menuIsOpen &&
                <div className="menu_mobile_inner">
                    <div className="col-12 col-md-3">
                        <div className="sc_layouts_item skocak-element my-auto">
                            <div className="skocak-widget-container text-center my-4">
                                <a href="/"
                                   className="sc_layouts_logo sc_layouts_logo_default">
                                    <img className="lazyload_inited logo_image"
                                         src={config.imagesPath + "brand-logo.jpg"}
                                         alt="Serap Koçak"/>
                                    <br/>
                                    Tasarımcı ve Örgü Eğitmeni
                                </a>
                            </div>
                            <div
                                className="pk-social-links-items d-flex px-4 justify-content-evenly mt-3 header-social-container d-md-flex">

                                {data.instagram.length > 0 &&
                                    <div className="pk-social-links-item pk-social-links-youtube">
                                        <a href={data.instagram}
                                           className="pk-social-links-link d-flex">
                                            <i className="fab fa-instagram d-flex justify-content-center"></i>
                                        </a>
                                    </div>
                                }

                                {data.youtube.length > 0 &&
                                    <div className="pk-social-links-item pk-social-links-youtube">
                                        <a href={data.youtube}
                                           className="pk-social-links-link d-flex">
                                            <i className="fab fa-youtube d-flex justify-content-center"></i>
                                        </a>
                                    </div>
                                }

                                {data.pinterest.length > 0 &&
                                    <div className="pk-social-links-item pk-social-links-youtube">
                                        <a href={data.pinterest}
                                           className="pk-social-links-link d-flex">
                                            <i className="fab fa-pinterest d-flex justify-content-center"></i>
                                        </a>
                                    </div>
                                }

                                {data.shopify.length > 0 &&
                                    <div className="pk-social-links-item pk-social-links-youtube">
                                        <a href={data.shopify}
                                           className="pk-social-links-link d-flex">
                                            <i className="fab fa-shopify d-flex justify-content-center"></i>
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={"mobile-menu-content"}>

                        <a className="menu_mobile_close" onClick={() => setMenuIsOpen(false)}><i
                            className={"fa fa-close menu_mobile_close"}></i></a>
                        <ul id="mobile-menu_mobile" className="menu_mobile_nav prepared">
                            {menu.map((m) => {
                                return getMenuItem(m)
                            })}
                        </ul>
                    </div>
                </div>
            }
            <div className={"container justify-content-center d-flex menu_desktop_inner"}>
                <ul id="menu_main" className="sc_layouts_menu_nav menu_main_nav m-auto">
                    {menu.map((m) => {
                        return getMenuItem(m)
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Header