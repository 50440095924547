import React, {useContext, useEffect, useRef, useState} from "react";
import HomePageService from "../../Services/HomePageService";
import './Contact.css';
import {LoaderContext} from "../../Services/LoaderContext";
import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import SuccessChekmark from "../Layout/Utils/SuccessCheckmark/SuccessChekmark";
import {Helmet} from "react-helmet-async";
import {useConfig} from "../../Services/ConfigContext";

const Contact = () => {

    const {showLoader, hideLoader} = useContext(LoaderContext);
    const recaptchaRef = useRef(null);

    const [pageContent, setPageContent] = useState({description: ""});

    const [form, setForm] = useState({name: '', email: '', message: ''})
    const [errors, setErrors] = useState({name: true, email: true, message: true});

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [captchaRequired, setCaptchaRequired] = useState(false);

    const [showSuccessMessage,setShowSuccessMessage] = useState(false);
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const beforeSubmit = () => {
        setFormSubmitted(true);
        if (!errors.name && !errors.message && !errors.email) {
            setCaptchaRequired(true);
        }
    }

    useEffect(() => {
        var checkErrors = {
            name: form.name.length < 5,
            message: form.message.length < 20,
            email: !validateEmail(form.email)
        }
        setErrors({...errors, ...checkErrors});
    }, [form]);


    const submit = (captcha) => {
        showLoader("Mesajınız gönderiliyor...");
        HomePageService.sendMessage({
            name: form.name,
            email: form.email,
            message: form.message,
            captcha: captcha
        }).then(response => {
            setForm({name: '',email: '',message: ''});
            setShowSuccessMessage(true);
            hideLoader();
        }).catch(e => {
            if(e.response.status === 429){
                showLoader("Çok sayıda istekte bulundunuz. Devam etmek için lütfen bekleyiniz...");
                setTimeout(() => {
                    hideLoader();
                },30*1000);
            }else{
                hideLoader();
            }
        }).finally(() => {
            setFormSubmitted(false);
            setCaptchaRequired(false);
        })
    }


    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }


    useEffect(() => {
        HomePageService.getContacts().then(response => {
            setPageContent(JSON.parse(response.data.pageContent));
        })
    }, []);


    const config = useConfig();
    const bannerData = config.banner;

    return (<div className={"page-wrapper contact-page-container"}>
            {bannerData &&  <Helmet>
                <title>{bannerData.metaTitle} - İletişim</title>
                <meta name="description" content={bannerData.metaDescription}/>
            </Helmet>
            }
            <div className={"container"}>
                <div className={"row"} dangerouslySetInnerHTML={{__html: pageContent.description}}></div>
            </div>
            <hr className={"my-5"}/>
            <div className={"container position-relative"}>
                <div className={"col-12 col-md-5 m-auto mb-5"}>
                    <div className={"col-12 mb-4 position-relative"}>
                        <label className={"col-12 mb-2"}>Adınız ve Soyadınız</label>
                        <input value={form.name} placeholder={"Ad Soyad"} type={"text"} onChange={(e) => handleForm("name",e.target.value)} className={"contact-input col-12"}/>
                        {(errors.name && formSubmitted) &&
                            <label className="error">Lütfen ad ve soyad bilgilerinizi giriniz</label>}
                    </div>
                    <div className={"col-12 mb-4 position-relative"}>

                        <label className={"col-12 mb-2"}>E-posta Adresiniz</label>
                        <input value={form.email} placeholder={"E-posta"} type={"email"}  onChange={(e) => handleForm("email",e.target.value)}  className={"contact-input col-12"}/>
                        {(errors.email && formSubmitted) &&
                            <label className="error">Lütfen e-posta adresinizi giriniz</label>}
                    </div>
                    <div className={"col-12 mb-4 position-relative"}>
                        <label className={"col-12 mb-2"}>Mesajınız</label>
                        <textarea value={form.message} placeholder={"Mesajınızı buraya yazınız..."} rows={4}  onChange={(e) => handleForm("message",e.target.value)}
                                  className={"contact-input col-12"}/>
                        {(errors.message && formSubmitted) &&
                            <label className="error">Lütfen mesajınızı en az 20 karakterden oluşacak şekilde giriniz</label>}
                    </div>

                    {!captchaRequired &&
                        <a onClick={beforeSubmit}
                           className="sc_button sc_button_default sc_button_size_small my-auto"><span
                            className="sc_button_title">MESAJI GÖNDER</span></a>
                    }
                    {captchaRequired &&
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LeQsFYqAAAAACPrq6_ZmkC5w58bVLvbtEZeCivu"
                            onChange={submit}/>
                    }
                </div>

                { showSuccessMessage &&
                <div className={"contact-success-message"}>
                    <SuccessChekmark></SuccessChekmark>
                    Mesajınız gönderildi
                    <a onClick={() => setShowSuccessMessage(false)}
                       className="sc_button sc_button_default sc_button_size_small mt-4 px-5"><span
                        className="sc_button_title">TAMAM</span></a>
                </div>
                }
            </div>
        </div>
    )


}

export default Contact;