import React from "react";
import {useConfig} from "../../../../Services/ConfigContext";

const PromoteBottomSectionItem = ({data}) => {

    const config = useConfig();

    return (<div className="sc_blogger_item sc_item_container post_container sc_blogger_item_list sc_blogger_item_list_with_rounded_image sc_blogger_item_odd sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_with_numbers sc_blogger_item_image_position_left sc_blogger_title_tag_h6 post-558 post type-post status-publish format-standard has-post-thumbnail hentry category-handmade tag-article tag-news tag-story">
            <div className="sc_blogger_item_body">
                <div className="post_featured with_thumb hover_inherit sc_item_featured sc_blogger_item_featured post_featured_bg" data-ratio="1:1">
                    <div className="featured_bg_wrapper">
                        <div className="featured_bg"
                             style={{backgroundImage: 'url(' + config.imagesPath + data.background + ')'}}></div>
                    </div>
                </div>
                <div className="sc_blogger_item_content entry-content">
                    <h6 className="sc_blogger_item_title entry-title"><a href={data.linkUrl}><span>{data.title}</span></a></h6>
                    <div className="post_meta sc_blogger_item_meta post_meta">
                        <span className="post_meta_item post_date"><a href={data.linkUrl}>{data.date}</a></span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromoteBottomSectionItem